<template>
  <div>
    <div
      style="
        position: fixed;
        z-index: 99999;
        width: 100%;
        height: 176px;
        background-color: #f2f2f2;
      "
    >
      <headV />
    </div>
    <div
      style="
        width: 100%;
        position: absolute;
        top: 176px;
        background-color: #f2f2f2;
      "
    >
      <!-- 资源上传 -->
      <div class="main">
        <div class="resource container">
          <div class="resource">
            <div class="resource_tit">
              <span>资源上传</span>
            </div>
            <div class="layuimini-main" style="margin-top: 20px">
              <fieldset
                class="table-search-fieldset"
                style="border: 1px solid #337ab7"
              >
                <div style="margin: 10px 10px 10px 10px">
                  <div class="layui-upload">
                    <button
                      type="button"
                      class="layui-btn layui-btn-primary layui-border-black"
                      id="testList"
                    >
                      <i class="layui-icon layui-icon-file"></i> 选择文件
                    </button>
                    <!--    <button type="button" class="layui-btn layui-btn-primary layui-border-green" id="testListAction"><i
							  class="layui-icon layui-icon-upload"></i> 开始上传 </button>-->
                    <button
                      type="button"
                      class="layui-btn layui-btn-primary layui-border-black"
                      id="clearList"
                      style="margin-left: 5px"
                    >
                      <i class="layui-icon layui-icon-fonts-clear"></i> 清空显示
                    </button>
                    <div
                      class="layui-upload-list"
                      style="max-width: 1280px; height: auto; min-height: 200px"
                    >
                      <table class="layui-table">
                        <colgroup>
                          <col />
                          <col width="150" />
                          <col width="260" />
                          <col width="260" />
                          <col width="200" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>文件名</th>
                            <th>大小</th>
                            <!--  <th>上传状态</th>
								  <th>上传进度</th> -->
                            <th>操作</th>
                          </tr>
                        </thead>
                        <tbody id="demoList"></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>

      <!-- footer -->
      <div class="footer_bg container" style="padding-top: 20px; height: 188px">
        <div class="footer">
          <foot />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import headV from "./head.vue";
import { ref, onMounted, onBeforeMount } from "vue";
import foot from "./footer.vue";
import service from "./service.vue";
import "../js/bootstrap-table";
//import "../js/layui/layui";
//import Layui from '@layui/layui-vue'
//import "@layui/layui-vue/lib/index.css";

const maptype = ref(null);

onMounted(() => {
  $("#leftPanel li").bind("click", function () {
    $("li").removeClass("srvSelected");
    $(this).addClass("srvSelected");
    maptype.value.inimap($(this).attr("id")); //调用子组件的方法
  });
  //==============
});
const layui = window.layui;
//上传资料界面
layui.use(["upload", "element", "layer"], function () {
  var $ = layui.jquery,
    upload = layui.upload,
    element = layui.element,
    layer = layui.layer;

  //清空文件列表
  $("#clearList").click(function () {
    $("#demoList").html("");
  });

  //多文件上传
  var uploadListIns = upload.render({
    elem: "#testList", // 选择文件按钮
    elemList: $("#demoList"), //列表元素对象
    // , url: '/file-upload' 此处用的是第三方的 http 请求演示，实际使用时改成您自己的上传接口即可。
    // , url: 'http://221.228.70.87:5020/api/qyxy_wjsc' //此处用的是第三方的 http 请求演示，实际使用时改成您自己的上传接口即可。
    accept: "file", //指定允许上传时校验的文件类型，可选值有：images（图片）、file（所有文件）、video（视频）、audio（音频）
    multiple: false, //是否允许多文件上传。设置 true即可开启。不支持ie8/9
    number: 0, //设置同时可上传的文件数量，一般配合 multiple 参数出现; 0 不限制
    auto: false, //是否选完文件后自动上传。如果设定 false，那么需要设置 bindAction 参数来指向一个其它按钮提交上传
    bindAction: "#testListAction", //指向一个按钮触发上传，一般配合 auto: false 来使用
    choose: function (obj) {
      //选择文件后的回调函数。返回一个object参数
      var that = this;
      var files = (this.files = obj.pushFile()); //将每次选择的文件追加到文件队列

      //读取本地文件
      obj.preview(function (index, file, result) {
        var fileName = file.name;
        var SRC = result;
        var SRC_sub = SRC.substring(SRC.indexOf(",", 0) + 1); //去除逗号前的内容
        var houzhui = fileName.split(".")[1];
        //console.log("文件上传：",houzhui );
        var tr = $(
          [
            '<tr id="upload-' + index + '">',
            "<td>" + file.name + "</td>",
            "<td>" + (file.size / 1014 / 1014).toFixed(1) + " Mb</td>",
            /*  , '<td>等待上传</td>' */
            "<td>",
            '<button type="button" class="layui-btn layui-btn-xs demo-reload layui-hide">重传</button>',
            '<button type="button" class="layui-btn layui-btn-xs" id = "startWJSC">开始上传</button>',
            "</td>",
            "</tr>",
          ].join("")
        );

        //单个重传
        tr.find(".demo-reload").on("click", function () {
          obj.upload(index, file);
        });

        //单个上传
        tr.find("#startWJSC").on("click", function () {
          /* var text = '文件上传中...'
							showLoading(layer, text)
							qyxy_wjsc(fileName, SRC_sub) */
          layer.open({
            type: 2,
            content: encodeURI("#/UserinfoEdit"), //弹窗网页地址
            btnAlign: "c", //按钮位置right

            btn: ["确定", "取消"],
            area: ["460px", "340px"],
            title: ["资源信息填写", "font-weight:600;font-size:16px;"],
            skin: "demo-skin",
            yes: function (i, layero) {
              var iframeWin = $(layero).find("iframe")[0].contentWindow; //得到iframe页的窗口对象，执行iframe页的方法：
              var obj = iframeWin.passUpdateVal();
              var sjnr = obj.sjnr;
              var sjfl = obj.sjfl;
              var blc = obj.blc;
              //console.log(sjnr, sjfl, blc, fileName, SRC)
              /* var editUserUrl = _jwturl +
										`Token/CorrPwd?username=${username}&name=${name}&oldPwd=${oldPwd}&newPwd=${newPwd}`;*/
              if (obj != "-1") {
                $.ajax({
                  type: "get",
                  url: "http://101.132.131.9:5010/api/Xzzy_tdtjy/Addtolist",
                  async: false,
                  data: {
                    sjnr: sjnr,
                    sjfl: sjfl,
                    blc: blc,
                    xzdz: fileName,
                  },
                  dataType: "json",
                  success: function (result) {},
                  error: function (XMLHttpRequest, textStatus, errorThrown) {},
                });
                sjwj(SRC, fileName);
              }
              layer.msg(
                "文件上传成功！",
                {
                  time: 1500,
                },
                function () {
                  layer.closeAll();
                  location.reload();
                }
              );
            },
          });
        });
        //删除
        tr.find(".demo-delete").on("click", function () {
          delete files[index]; //删除对应的文件
          tr.remove();
          uploadListIns.config.elem.next()[0].value = ""; //清空 input file 值，以免删除后出现同名文件不可选
        });

        that.elemList.append(tr);
        element.render("progress"); //渲染新加的进度条组件
      });
    },
    done: function (res, index, upload) {
      //成功的回调
      var that = this;
      if (res.code == 0) {
        //上传成功

        var tr = that.elemList.find("tr#upload-" + index),
          tds = tr.children();
        // tds.remove();
        tds.eq(2).html('<span style="color:green;">上传成功</span>'); //显示上传成功
        tds.eq(3).html(""); //清空操作
        delete this.files[index]; //删除文件队列已经上传成功的文件
        return;
      }
      this.error(index, upload);
    },
    allDone: function (obj) {
      //多文件上传完毕后的状态回调
      console.log(obj);
    },
    error: function (index, upload) {
      //错误回调
      var that = this;
      var tr = that.elemList.find("tr#upload-" + index),
        tds = tr.children();
      tds.eq(2).html('<span style="color:red;">上传失败</span>'); //显示上传成功
      tds.eq(3).find(".demo-reload").removeClass("layui-hide"); //显示重传
    },
    progress: function (n, elem, e, index) {
      //注意：index 参数为 layui 2.6.6 新增
      element.progress("progress-demo-" + index, n + "%"); //执行进度条。n 即为返回的进度百分比
    },
  });
});

function sjwj(file, filename) {
  var blob = dataURItoBlob(file); // 上一步中的函数
  var canvas = document.createElement("canvas");
  var dataURL = canvas.toDataURL("image/jpeg", 0.5);
  var fd = new FormData(document.forms[0]);
  fd.append("the_file", blob, filename);
  $.ajax({
    type: "post",
    url: "http://101.132.131.9:5010/api/Xzzy_tdtjy/EventUpload",
    data: fd,
    async: true,
    cache: false,
    contentType: false,
    processData: false,
    success: function (result) {
      console.log(result)
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {
      console.log(XMLHttpRequest, textStatus, errorThrown);
    },
  });
}

function dataURItoBlob(base64Data) {
  var byteString;
  var bd = base64Data;
  if (bd.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(bd.split(",")[1]);
  else byteString = unescape(bd.split(",")[1]);
  var mimeString = bd.split(",")[0].split(":")[1].split(";")[0];
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

function showLoading(layer, text) {
  layer.msg(text, {
    icon: 16, //代表加载的图标

    time: 0, //0秒关闭(如果不配置，默认是3秒)

    shade: 0.5,
  });
}

// 查询结果后隐藏状态
function hideLoading() {
  layer.close(layer.msg());
  // window.layer.closeAll();
}
</script>
<style scoped>
/* @import url("../css/nav.css");
@import url("../css/index.css");
@import url("../css/resource.css"); */
@import url("../css/fresh-bootstrap-table.css");

.layui-btn-primary {
  background-color: white;
}
th {
  width: 35%;
}
</style>