<script>
var jj = "http://smartcity1.mapjiangyin.com/KMap/rest/services/jyjj/MapServer";
var mapextend = "120.78,31.08,121.17,31.56";

var centerX = 120.296023;
var centerY = 31.920433;
var sheetNumber = "苏B(2023)011号-甲测资字32100598";
var DL =
  "餐饮;住宿;地名地址;公共设施;公司企业;交通运输、仓储;教育、文化;金融、保险;居民服务;批发、零售;汽车销售及服务;卫生、社保;运动、休闲";



var sldt_jy =
  "http://smartcity1.mapjiangyin.com/KMap/rest/services/jyslL17_L19/MapServer";

var sldt_zj =
  "http://smartcity1.mapjiangyin.com/KMap/rest/services/jyslzj/MapServer";
var yxdt_jy =
  "http://smartcity1.mapjiangyin.com/KMap/rest/services/jyyx/MapServer";
var yxzj_jy =
  "http://smartcity1.mapjiangyin.com/KMap/rest/services/jyyxzj/MapServer";
  var poi_jy =
  "http://smartcity1.mapjiangyin.com/KMap/rest/services/JYTDT/MapServer/1";
  var jyRespl =
  "http://smartcity1.mapjiangyin.com/KMap/rest/services/jyRespl/MapServer/0";
  var jysd =
  "http://smartcity1.mapjiangyin.com/KMap/rest/services/jysd/MapServer/0";

export default {
  jj,
  DL,
  mapextend,
  centerX,
  centerY,
  sheetNumber,
  sldt_jy,
  sldt_zj,
  yxdt_jy,
  yxzj_jy,
  poi_jy,
  jyRespl,
  jysd
 
};
</script>
