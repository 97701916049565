L.Control.Draw = L.Control.extend({

    options: {
        position: 'topleft',
        draw: {}
    },

    initialize: function (options) {
        if (L.version < '0.7') {
            throw new Error('Leaflet.draw 0.2.3+ requires Leaflet 0.7.0+. Download latest from https://github.com/Leaflet/Leaflet/');
        }

        L.Control.prototype.initialize.call(this, options);

        var toolbar;

        this._toolbars = {};

        // Initialize toolbars
        if (L.DrawToolbar && this.options.draw) {
            toolbar = new L.DrawToolbar(this.options.draw);

            this._toolbars[L.DrawToolbar.TYPE] = toolbar;

            // Listen for when toolbar is enabled
            this._toolbars[L.DrawToolbar.TYPE].on('enable', this._toolbarEnabled, this);
        }

        if (L.EditToolbar && this.options.edit) {
            toolbar = new L.EditToolbar(this.options.edit);

            this._toolbars[L.EditToolbar.TYPE] = toolbar;

            // Listen for when toolbar is enabled
            this._toolbars[L.EditToolbar.TYPE].on('enable', this._toolbarEnabled, this);
        }
    },

    onAdd: function (map) {
        var container = L.DomUtil.create('div', 'leaflet-draw'),
            addedTopClass = false,
            topClassName = 'leaflet-draw-toolbar-top',
            toolbarContainer;

        for (var toolbarId in this._toolbars) {
            if (this._toolbars.hasOwnProperty(toolbarId)) {
                toolbarContainer = this._toolbars[toolbarId].addToolbar(map);

                if (toolbarContainer) {
                    // Add class to the first toolbar to remove the margin
                    if (!addedTopClass) {
                        if (!L.DomUtil.hasClass(toolbarContainer, topClassName)) {
                            L.DomUtil.addClass(toolbarContainer.childNodes[0], topClassName);
                        }
                        addedTopClass = true;
                    }

                    container.appendChild(toolbarContainer);
                }
            }
        }

        return container;
    },

    onRemove: function () {
        for (var toolbarId in this._toolbars) {
            if (this._toolbars.hasOwnProperty(toolbarId)) {
                this._toolbars[toolbarId].removeToolbar();
            }
        }
    },

    setDrawingOptions: function (options) {
        for (var toolbarId in this._toolbars) {
            if (this._toolbars[toolbarId] instanceof L.DrawToolbar) {
                this._toolbars[toolbarId].setOptions(options);
            }
        }
    },

    _toolbarEnabled: function (e) {
        var enabledToolbar = e.target;

        for (var toolbarId in this._toolbars) {
            if (this._toolbars[toolbarId] !== enabledToolbar) {
                this._toolbars[toolbarId].disable();
            }
        }
    }
});

L.Map.mergeOptions({
    drawControlTooltips: true,
    drawControl: false
});

L.Map.addInitHook(function () {
    if (this.options.drawControl) {
        this.drawControl = new L.Control.Draw();
        this.addControl(this.drawControl);
    }
});

/*
 * Leaflet.draw assumes that you have already included the Leaflet library.
 */

L.drawVersion = '0.2.4-dev';

L.drawLocal = {
    draw: {
        toolbar: {
            actions: {
                title: '取消测量',
                text: '取消测量'
            },
            undo: {
                title: '删除最后一个点',
                text: '删除最后一个点'
            },
            buttons: {
                polyline: '长度测量',
                polygon: '面积测量'
            }
        },
        handlers: {
            polygon: {
                tooltip: {
                    start: '单击开始画多边形',
                    cont: '单击继续画多边形',
                    end: '双击结束画多边形'
                }
            },
            polyline: {
                error: '<strong>错误：</strong>边线自相交！',
                tooltip: {
                    start: '单击开始画线',
                    cont: '单击继续画线',
                    end: '双击结束画线'
                }
            }
        }
    }
};

L.Toolbar = L.Class.extend({
    includes: [L.Mixin.Events],

    initialize: function (options) {
        L.setOptions(this, options);

        this._modes = {};
        this._actionButtons = [];
        this._activeMode = null;
    },

    enabled: function () {
        return this._activeMode !== null;
    },

    disable: function () {
        if (!this.enabled()) { return; }

        this._activeMode.handler.disable();
    },

    addToolbar: function (map) {
        var container = L.DomUtil.create('div', 'leaflet-draw-section'),
            buttonIndex = 0,
            buttonClassPrefix = this._toolbarClass || '',
            modeHandlers = this.getModeHandlers(map),
            i;

        this._toolbarContainer = L.DomUtil.create('div', 'leaflet-draw-toolbar leaflet-bar');
        this._map = map;

        for (i = 0; i < modeHandlers.length; i++) {
            if (modeHandlers[i].enabled) {
                this._initModeHandler(
                    modeHandlers[i].handler,
                    this._toolbarContainer,
                    buttonIndex++,
                    buttonClassPrefix,
                    modeHandlers[i].title
                );
            }
        }

        // if no buttons were added, do not add the toolbar
        if (!buttonIndex) {
            return;
        }

        // Save button index of the last button, -1 as we would have ++ after the last button
        this._lastButtonIndex = --buttonIndex;

        // Create empty actions part of the toolbar
        this._actionsContainer = L.DomUtil.create('ul', 'leaflet-draw-actions');

        // Add draw and cancel containers to the control container
        container.appendChild(this._toolbarContainer);
        container.appendChild(this._actionsContainer);

        return container;
    },

    removeToolbar: function () {
        // Dispose each handler
        for (var handlerId in this._modes) {
            if (this._modes.hasOwnProperty(handlerId)) {
                // Unbind handler button
                this._disposeButton(
                    this._modes[handlerId].button,
                    this._modes[handlerId].handler.enable,
                    this._modes[handlerId].handler
                );

                // Make sure is disabled
                this._modes[handlerId].handler.disable();

                // Unbind handler
                this._modes[handlerId].handler
                    .off('enabled', this._handlerActivated, this)
                    .off('disabled', this._handlerDeactivated, this);
            }
        }
        this._modes = {};

        // Dispose the actions toolbar
        for (var i = 0, l = this._actionButtons.length; i < l; i++) {
            this._disposeButton(
                this._actionButtons[i].button,
                this._actionButtons[i].callback,
                this
            );
        }
        this._actionButtons = [];
        this._actionsContainer = null;
    },

    _initModeHandler: function (handler, container, buttonIndex, classNamePredix, buttonTitle) {
        var type = handler.type;

        this._modes[type] = {};

        this._modes[type].handler = handler;

        this._modes[type].button = this._createButton({
            title: buttonTitle,
            className: classNamePredix + '-' + type,
            container: container,
            callback: this._modes[type].handler.enable,
            context: this._modes[type].handler
        });

        this._modes[type].buttonIndex = buttonIndex;

        this._modes[type].handler
            .on('enabled', this._handlerActivated, this)
            .on('disabled', this._handlerDeactivated, this);
    },

    _createButton: function (options) {
        var link = L.DomUtil.create('a', options.className || '', options.container);
        link.href = '#';

        if (options.text) {
            link.innerHTML = options.text;
        }

        if (options.title) {
            link.title = options.title;
        }

        L.DomEvent
            .on(link, 'click', L.DomEvent.stopPropagation)
            .on(link, 'mousedown', L.DomEvent.stopPropagation)
            .on(link, 'dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.preventDefault)
            .on(link, 'click', options.callback, options.context);

        return link;
    },

    _disposeButton: function (button, callback) {
        L.DomEvent
            .off(button, 'click', L.DomEvent.stopPropagation)
            .off(button, 'mousedown', L.DomEvent.stopPropagation)
            .off(button, 'dblclick', L.DomEvent.stopPropagation)
            .off(button, 'click', L.DomEvent.preventDefault)
            .off(button, 'click', callback);
    },

    _handlerActivated: function (e) {
        // Disable active mode (if present)
        this.disable();

        // Cache new active feature
        this._activeMode = this._modes[e.handler];

        L.DomUtil.addClass(this._activeMode.button, 'leaflet-draw-toolbar-button-enabled');

        this._showActionsToolbar();

        this.fire('enable');
    },

    _handlerDeactivated: function () {
        this._hideActionsToolbar();

        L.DomUtil.removeClass(this._activeMode.button, 'leaflet-draw-toolbar-button-enabled');

        this._activeMode = null;

        this.fire('disable');
    },

    _createActions: function (handler) {
        var container = this._actionsContainer,
            buttons = this.getActions(handler),
            l = buttons.length,
            li, di, dl, button;

        // Dispose the actions toolbar (todo: dispose only not used buttons)
        for (di = 0, dl = this._actionButtons.length; di < dl; di++) {
            this._disposeButton(this._actionButtons[di].button, this._actionButtons[di].callback);
        }
        this._actionButtons = [];

        // Remove all old buttons
        while (container.firstChild) {
            container.removeChild(container.firstChild);
        }

        for (var i = 0; i < l; i++) {
            if ('enabled' in buttons[i] && !buttons[i].enabled) {
                continue;
            }

            li = L.DomUtil.create('li', '', container);

            button = this._createButton({
                title: buttons[i].title,
                text: buttons[i].text,
                container: li,
                callback: buttons[i].callback,
                context: buttons[i].context
            });

            this._actionButtons.push({
                button: button,
                callback: buttons[i].callback
            });
        }
    },

    _showActionsToolbar: function () {
        var buttonIndex = this._activeMode.buttonIndex,
            lastButtonIndex = this._lastButtonIndex,
            toolbarPosition = this._activeMode.button.offsetTop - 1;

        // Recreate action buttons on every click
        this._createActions(this._activeMode.handler);

        // Correctly position the cancel button
        this._actionsContainer.style.top = toolbarPosition + 'px';

        if (buttonIndex === 0) {
            L.DomUtil.addClass(this._toolbarContainer, 'leaflet-draw-toolbar-notop');
            L.DomUtil.addClass(this._actionsContainer, 'leaflet-draw-actions-top');
        }

        if (buttonIndex === lastButtonIndex) {
            L.DomUtil.addClass(this._toolbarContainer, 'leaflet-draw-toolbar-nobottom');
            L.DomUtil.addClass(this._actionsContainer, 'leaflet-draw-actions-bottom');
        }

        this._actionsContainer.style.display = 'block';
    },

    _hideActionsToolbar: function () {
        this._actionsContainer.style.display = 'none';

        L.DomUtil.removeClass(this._toolbarContainer, 'leaflet-draw-toolbar-notop');
        L.DomUtil.removeClass(this._toolbarContainer, 'leaflet-draw-toolbar-nobottom');
        L.DomUtil.removeClass(this._actionsContainer, 'leaflet-draw-actions-top');
        L.DomUtil.removeClass(this._actionsContainer, 'leaflet-draw-actions-bottom');
    }
});

L.DrawToolbar = L.Toolbar.extend({

	statics: {
		TYPE: 'draw'
	},

	options: {
		polyline: {},
		polygon: {},
        clearmap:{}
	},

	initialize: function (options) {
		// Ensure that the options are merged correctly since L.extend is only shallow
		for (var type in this.options) {
			if (this.options.hasOwnProperty(type)) {
				if (options[type]) {
					options[type] = L.extend({}, this.options[type], options[type]);
				}
			}
		}

		this._toolbarClass = 'leaflet-draw-draw';
		L.Toolbar.prototype.initialize.call(this, options);
	},

	getModeHandlers: function (map) {
		return [
			{
				enabled: this.options.polyline,
				handler: new L.Draw.Polyline(map, this.options.polyline),
				title: L.drawLocal.draw.toolbar.buttons.polyline
			},
			{
				enabled: this.options.polygon,
				handler: new L.Draw.Polygon(map, this.options.polygon),
				title: L.drawLocal.draw.toolbar.buttons.polygon
			},
            {
                enabled: this.options.clearmap,
                handler: new L.Draw.clearmap(map, this.options.clearmap),
                title:"清除测量标记"
            }
			/*{
				enabled: this.options.rectangle,
				handler: new L.Draw.Rectangle(map, this.options.rectangle),
				title: L.drawLocal.draw.toolbar.buttons.rectangle
			},
			{
				enabled: this.options.circle,
				handler: new L.Draw.Circle(map, this.options.circle),
				title: L.drawLocal.draw.toolbar.buttons.circle
			},
			{
				enabled: this.options.marker,
				handler: new L.Draw.Marker(map, this.options.marker),
				title: L.drawLocal.draw.toolbar.buttons.marker
			}*/
		];
	},

	// Get the actions part of the toolbar
	getActions: function (handler) {
		return [
			// {
			// 	enabled: handler.hasActionBtn,
			// 	title: L.drawLocal.draw.toolbar.undo.title,
			// 	text: L.drawLocal.draw.toolbar.undo.text,
			// 	callback: handler.deleteLastVertex,
			// 	context: handler
			// },
			// {
			// 	enabled:handler.hasActionBtn,
            //     title: L.drawLocal.draw.toolbar.actions.title,
			// 	text: L.drawLocal.draw.toolbar.actions.text,
			// 	callback: this.disable,
			// 	context: this
			// }
		];
	},

	setOptions: function (options) {
		L.setOptions(this, options);

		for (var type in this._modes) {
			if (this._modes.hasOwnProperty(type) && options.hasOwnProperty(type)) {
				this._modes[type].handler.setOptions(options[type]);
			}
		}
	}
});

//Draw.Feature
L.Draw = {};

L.Draw.Feature = L.Handler.extend({
    includes: L.Mixin.Events,

    initialize: function (map, options) {
        this._map = map;
        this._container = map._container;
        this._overlayPane = map._panes.overlayPane;
        this._popupPane = map._panes.popupPane;

        // Merge default shapeOptions options with custom shapeOptions
        if (options && options.shapeOptions) {
            options.shapeOptions = L.Util.extend({}, this.options.shapeOptions, options.shapeOptions);
        }
        L.setOptions(this, options);
    },

    enable: function () {
        if (this._enabled) { return; }

        L.Handler.prototype.enable.call(this);

        this.fire('enabled', { handler: this.type });

        this._map.fire('draw:drawstart', { layerType: this.type });
    },

    disable: function () {
        if (!this._enabled) { return; }

        L.Handler.prototype.disable.call(this);

        this._map.fire('draw:drawstop', { layerType: this.type });

        this.fire('disabled', { handler: this.type });
    },

    addHooks: function () {
        var map = this._map;

        if (map) {
            L.DomUtil.disableTextSelection();

            map.getContainer().focus();

            this._tooltip = new L.Tooltip(this._map);

            L.DomEvent.on(this._container, 'keyup', this._cancelDrawing, this);
        }
    },

    removeHooks: function () {
        if (this._map) {
            L.DomUtil.enableTextSelection();

            this._tooltip.dispose();
            this._tooltip = null;

            L.DomEvent.off(this._container, 'keyup', this._cancelDrawing, this);
        }
    },

    setOptions: function (options) {
        L.setOptions(this, options);
    },

    _fireCreatedEvent: function (tip,layer) {
        this._map.fire('draw:created', { layer: layer,tip:tip,layerType: this.type });
    },

    // Cancel drawing when the escape key is pressed
    _cancelDrawing: function (e) {
        if (e.keyCode === 27) {
            this.disable();
        }
    }
});

//Draw.Polyline
L.Draw.Polyline = L.Draw.Feature.extend({
    statics: {
        TYPE: 'polyline'
    },

    Poly: L.Polyline,

    hasActionBtn:true,

    options: {
        allowIntersection: true,
        repeatMode: false,
        drawError: {
            color: '#b00b00',
            timeout: 2500
        },
        icon: new L.DivIcon({
            iconSize: new L.Point(8, 8),
            className: 'leaflet-div-icon leaflet-editing-icon'
        }),
        guidelineDistance: 20,
        maxGuideLineLength: 4000,
        shapeOptions: {
            stroke: true,
            color: '#009DFF',
            weight: 3,
            opacity: 1,
            fill: false,
            clickable: true
        },
        metric: true, // Whether to use the metric meaurement system or imperial
        showLength: true, // Whether to display distance in the tooltip
        zIndexOffset: 2000 // This should be > than the highest z-index any map layers
    },

    initialize: function (map, options) {
        // Need to set this here to ensure the correct message is used.
        this.options.drawError.message = L.drawLocal.draw.handlers.polyline.error;

        // Merge default drawError options with custom options
        if (options && options.drawError) {
            options.drawError = L.Util.extend({}, this.options.drawError, options.drawError);
        }

        // Save the type so super can fire, need to do this as cannot do this.TYPE :(
        this.type = L.Draw.Polyline.TYPE;

        L.Draw.Feature.prototype.initialize.call(this, map, options);
    },

    addHooks: function () {
        L.Draw.Feature.prototype.addHooks.call(this);
        if (this._map) {
            this._markers = [];

            this._markerGroup = new L.LayerGroup();
            this._map.addLayer(this._markerGroup);

            this._poly = new L.Polyline([], this.options.shapeOptions);

            this._tooltip.updateContent(this._getTooltipText());

            // Make a transparent marker that will used to catch click events. These click
            // events will create the vertices. We need to do this so we can ensure that
            // we can create vertices over other map layers (markers, vector layers). We
            // also do not want to trigger any click handlers of objects we are clicking on
            // while drawing.
            if (!this._mouseMarker) {
                this._mouseMarker = L.marker(this._map.getCenter(), {
                    icon: L.divIcon({
                        className: 'leaflet-mouse-marker',
                        iconAnchor: [20, 20],
                        iconSize: [40, 40]
                    }),
                    opacity: 0,
                    zIndexOffset: this.options.zIndexOffset
                });
            }

            this._mouseMarker
                .on('mousedown', this._onMouseDown, this)
                .addTo(this._map);

            this._map
                .on('mousemove', this._onMouseMove, this)
                .on('mouseup', this._onMouseUp, this)
                .on('zoomend', this._onZoomEnd, this);
        }
    },

    removeHooks: function () {
        L.Draw.Feature.prototype.removeHooks.call(this);

        this._clearHideErrorTimeout();

        this._cleanUpShape();

        // remove markers from map
        this._map.removeLayer(this._markerGroup);
        delete this._markerGroup;
        delete this._markers;

        this._map.removeLayer(this._poly);
        delete this._poly;

        this._mouseMarker
            .off('mousedown', this._onMouseDown, this)
            .off('mouseup', this._onMouseUp, this);
        this._map.removeLayer(this._mouseMarker);
        delete this._mouseMarker;

        // clean up DOM
        this._clearGuides();

        this._map
            .off('mousemove', this._onMouseMove, this)
            .off('zoomend', this._onZoomEnd, this);
    },

    deleteLastVertex: function () {
        if (this._markers.length <= 1) {
            return;
        }

        var lastMarker = this._markers.pop(),
            poly = this._poly,
            latlng = this._poly.spliceLatLngs(poly.getLatLngs().length - 1, 1)[0];

        this._markerGroup.removeLayer(lastMarker);

        if (poly.getLatLngs().length < 2) {
            this._map.removeLayer(poly);
        }

        this._vertexChanged(latlng, false);
    },

    addVertex: function (latlng) {
        var markersLength = this._markers.length;

        if (markersLength > 0 && !this.options.allowIntersection && this._poly.newLatLngIntersects(latlng)) {
            this._showErrorTooltip();
            return;
        }
        else if (this._errorShown) {
            this._hideErrorTooltip();
        }

        this._markers.push(this._createMarker(latlng));

        this._poly.addLatLng(latlng);

        if (this._poly.getLatLngs().length === 2) {
            this._map.addLayer(this._poly);
        }

        this._vertexChanged(latlng, true);
    },

    _finishShape: function () {
        var intersects = this._poly.newLatLngIntersects(this._poly.getLatLngs()[0], true);

        if ((!this.options.allowIntersection && intersects) || !this._shapeIsValid()) {
            this._showErrorTooltip();
            return;
        }

        this._fireCreatedEvent();
        this.disable();
        if (this.options.repeatMode) {
            this.enable();
        }

        //this._poly.bindPopup('A popup!');
    },

    //Called to verify the shape is valid when the user tries to finish it
    //Return false if the shape is not valid
    _shapeIsValid: function () {
        return true;
    },

    _onZoomEnd: function () {
        this._updateGuide();
    },

    _onMouseMove: function (e) {
        var newPos = e.layerPoint,
            latlng = e.latlng;

        // Save latlng
        // should this be moved to _updateGuide() ?
        this._currentLatLng = latlng;

        this._updateTooltip(latlng);

        // Update the guide line
        this._updateGuide(newPos);

        // Update the mouse marker position
        this._mouseMarker.setLatLng(latlng);

        L.DomEvent.preventDefault(e.originalEvent);
    },

    _vertexChanged: function (latlng, added) {
        this._updateFinishHandler();

        this._updateRunningMeasure(latlng, added);

        this._clearGuides();

        this._updateTooltip();
    },

    _onMouseDown: function (e) {
        var originalEvent = e.originalEvent;
        this._mouseDownOrigin = L.point(originalEvent.clientX, originalEvent.clientY);
    },

    _onMouseUp: function (e) {
        if (this._mouseDownOrigin) {
            // We detect clicks within a certain tolerance, otherwise let it
            // be interpreted as a drag by the map
            var distance = L.point(e.originalEvent.clientX, e.originalEvent.clientY)
                .distanceTo(this._mouseDownOrigin);
            if (Math.abs(distance) < 9 * (window.devicePixelRatio || 1)) {
                this.addVertex(e.latlng);
            }
        }
        this._mouseDownOrigin = null;
    },

    _updateFinishHandler: function () {
        var markerCount = this._markers.length;
        // The last marker should have a click handler to close the polyline
        if (markerCount > 1) {
            this._markers[markerCount - 1].on('click', this._finishShape, this);
        }

        // Remove the old marker click handler (as only the last point should close the polyline)
        if (markerCount > 2) {
            this._markers[markerCount - 2].off('click', this._finishShape, this);
        }
    },

    _createMarker: function (latlng) {
        var marker = new L.Marker(latlng, {
            icon: this.options.icon,
            zIndexOffset: this.options.zIndexOffset * 2
        });

        this._markerGroup.addLayer(marker);

        return marker;
    },

    _updateGuide: function (newPos) {
        var markerCount = this._markers.length;

        if (markerCount > 0) {
            newPos = newPos || this._map.latLngToLayerPoint(this._currentLatLng);

            // draw the guide line
            this._clearGuides();
            this._drawGuide(
                this._map.latLngToLayerPoint(this._markers[markerCount - 1].getLatLng()),
                newPos
            );
        }
    },

    _updateTooltip: function (latLng) {
        var text = this._getTooltipText();

        if (latLng) {
            this._tooltip.updatePosition(latLng);
        }

        if (!this._errorShown) {
            this._tooltip.updateContent(text);
        }
    },

    _drawGuide: function (pointA, pointB) {
        var length = Math.floor(Math.sqrt(Math.pow((pointB.x - pointA.x), 2) + Math.pow((pointB.y - pointA.y), 2))),
            guidelineDistance = this.options.guidelineDistance,
            maxGuideLineLength = this.options.maxGuideLineLength,
        // Only draw a guideline with a max length
            i = length > maxGuideLineLength ? length - maxGuideLineLength : guidelineDistance,
            fraction,
            dashPoint,
            dash;

        //create the guides container if we haven't yet
        if (!this._guidesContainer) {
            this._guidesContainer = L.DomUtil.create('div', 'leaflet-draw-guides', this._overlayPane);
        }

        //draw a dash every GuildeLineDistance
        for (; i < length; i += this.options.guidelineDistance) {
            //work out fraction along line we are
            fraction = i / length;

            //calculate new x,y point
            dashPoint = {
                x: Math.floor((pointA.x * (1 - fraction)) + (fraction * pointB.x)),
                y: Math.floor((pointA.y * (1 - fraction)) + (fraction * pointB.y))
            };

            //add guide dash to guide container
            dash = L.DomUtil.create('div', 'leaflet-draw-guide-dash', this._guidesContainer);
            dash.style.backgroundColor =
                !this._errorShown ? this.options.shapeOptions.color : this.options.drawError.color;

            L.DomUtil.setPosition(dash, dashPoint);
        }
    },

    _updateGuideColor: function (color) {
        if (this._guidesContainer) {
            for (var i = 0, l = this._guidesContainer.childNodes.length; i < l; i++) {
                this._guidesContainer.childNodes[i].style.backgroundColor = color;
            }
        }
    },

    // removes all child elements (guide dashes) from the guides container
    _clearGuides: function () {
        if (this._guidesContainer) {
            while (this._guidesContainer.firstChild) {
                this._guidesContainer.removeChild(this._guidesContainer.firstChild);
            }
        }
    },

    _getTooltipText: function () {
        var showLength = this.options.showLength,
            labelText, distanceStr;

        if (this._markers.length === 0) {
            labelText = {
                text: L.drawLocal.draw.handlers.polyline.tooltip.start
            };
        } else {
            distanceStr = showLength ? this._getMeasurementString() : '';

            if (this._markers.length === 1) {
                labelText = {
                    text: L.drawLocal.draw.handlers.polyline.tooltip.cont,
                    subtext: distanceStr
                };
            } else {
                labelText = {
                    text: L.drawLocal.draw.handlers.polyline.tooltip.end,
                    subtext: distanceStr
                };
            }
        }
        return labelText;
    },

    _updateRunningMeasure: function (latlng, added) {
        var markersLength = this._markers.length,
            previousMarkerIndex, distance;

        if (this._markers.length === 1) {
            this._measurementRunningTotal = 0;
        } else {
            previousMarkerIndex = markersLength - (added ? 2 : 1);
            distance = latlng.distanceTo(this._markers[previousMarkerIndex].getLatLng());

            this._measurementRunningTotal += distance * (added ? 1 : -1);
        }
    },

    _getMeasurementString: function () {
        var currentLatLng = this._currentLatLng,
            previousLatLng = this._markers[this._markers.length - 1].getLatLng(),
            distance;

        // calculate the distance from the last fixed point to the mouse position
        distance = this._measurementRunningTotal + currentLatLng.distanceTo(previousLatLng);

        return L.GeometryUtil.readableDistance(distance, this.options.metric);
    },

    _showErrorTooltip: function () {
        this._errorShown = true;

        // Update tooltip
        this._tooltip
            .showAsError()
            .updateContent({ text: this.options.drawError.message });

        // Update shape
        this._updateGuideColor(this.options.drawError.color);
        this._poly.setStyle({ color: this.options.drawError.color });

        // Hide the error after 2 seconds
        this._clearHideErrorTimeout();
        this._hideErrorTimeout = setTimeout(L.Util.bind(this._hideErrorTooltip, this), this.options.drawError.timeout);
    },

    _hideErrorTooltip: function () {
        this._errorShown = false;

        this._clearHideErrorTimeout();

        // Revert tooltip
        this._tooltip
            .removeError()
            .updateContent(this._getTooltipText());

        // Revert shape
        this._updateGuideColor(this.options.shapeOptions.color);
        this._poly.setStyle({ color: this.options.shapeOptions.color });
    },

    _clearHideErrorTimeout: function () {
        if (this._hideErrorTimeout) {
            clearTimeout(this._hideErrorTimeout);
            this._hideErrorTimeout = null;
        }
    },

    _cleanUpShape: function () {
        if (this._markers.length > 1) {
            this._markers[this._markers.length - 1].off('click', this._finishShape, this);
        }
    },

    _fireCreatedEvent: function () {
        var poly = new this.Poly(this._poly.getLatLngs(), this.options.shapeOptions);
        var tip=this._getMeasurementString();
        L.Draw.Feature.prototype._fireCreatedEvent.call(this,tip, poly);
    }
});

//Draw.Polygon
L.Draw.Polygon = L.Draw.Polyline.extend({
    statics: {
        TYPE: 'polygon'
    },

    Poly: L.Polygon,

    options: {
        showArea: false,
        shapeOptions: {
            stroke: true,
            color: '#009DFF',
            weight: 3,
            opacity: 0.5,
            fill: true,
            fillColor: null, //same as color by default
            fillOpacity: 0.2,
            clickable: true
        }
    },

    initialize: function (map, options) {
        L.Draw.Polyline.prototype.initialize.call(this, map, options);

        // Save the type so super can fire, need to do this as cannot do this.TYPE :(
        this.type = L.Draw.Polygon.TYPE;
    },

    _updateFinishHandler: function () {
        var markerCount = this._markers.length;

        // The first marker should have a click handler to close the polygon
        if (markerCount === 1) {
            this._markers[0].on('click', this._finishShape, this);
        }

        // Add and update the double click handler
        if (markerCount > 2) {
            this._markers[markerCount - 1].on('dblclick', this._finishShape, this);
            // Only need to remove handler if has been added before
            if (markerCount > 3) {
                this._markers[markerCount - 2].off('dblclick', this._finishShape, this);
            }
        }
    },

    _getTooltipText: function () {
        var text, subtext;

        if (this._markers.length === 0) {
            text = L.drawLocal.draw.handlers.polygon.tooltip.start;
        } else if (this._markers.length < 3) {
            text = L.drawLocal.draw.handlers.polygon.tooltip.cont;
        } else {
            text = L.drawLocal.draw.handlers.polygon.tooltip.end;
            subtext = this._getMeasurementString();
        }

        return {
            text: text,
            subtext: subtext
        };
    },

    _getMeasurementString: function () {
        var area = this._area;

        if (!area) {
            return null;
        }

        return L.GeometryUtil.readableArea(area, this.options.metric);
    },

    _shapeIsValid: function () {
        return this._markers.length >= 3;
    },

    _vertexChanged: function (latlng, added) {
        var latLngs;

        // Check to see if we should show the area
        if (!this.options.allowIntersection && this.options.showArea) {
            latLngs = this._poly.getLatLngs();

            this._area = L.GeometryUtil.geodesicArea(latLngs);
        }

        L.Draw.Polyline.prototype._vertexChanged.call(this, latlng, added);
    },

    _cleanUpShape: function () {
        var markerCount = this._markers.length;

        if (markerCount > 0) {
            this._markers[0].off('click', this._finishShape, this);

            if (markerCount > 2) {
                this._markers[markerCount - 1].off('dblclick', this._finishShape, this);
            }
        }
    }
});

//清除标注信息
L.Draw.clearmap = L.Handler.extend({
    statics: {
        TYPE: 'clearmap'
    },

    includes: L.Mixin.Events,

    hasActionBtn:false,

    initialize: function (map, options) {
        L.Handler.prototype.initialize.call(this, map);

        L.Util.setOptions(this, options);

        // Store the selectable layer group for ease of access
        this._deletableLayers = this.options.featureGroup;

        if (!(this._deletableLayers instanceof L.FeatureGroup)) {
            throw new Error('options.featureGroup must be a L.FeatureGroup');
        }

        // Save the type so super can fire, need to do this as cannot do this.TYPE :(
        this.type = L.Draw.clearmap.TYPE;
        
    },

    enable: function () {
        if (this._enabled || !this._hasAvailableLayers()) {
            return;
        }
        this.fire('enabled', { handler: this.type});

        //this._map.fire('draw:deletestart', { handler: this.type });

        L.Handler.prototype.enable.call(this);

       /* this._deletableLayers
            .on('layeradd', this._enableLayerDelete, this)
            .on('layerremove', this._disableLayerDelete, this);*/
    },

    disable: function () {
        if (!this._enabled) { return; }

     /*   this._deletableLayers
            .off('layeradd', this._enableLayerDelete, this)
            .off('layerremove', this._disableLayerDelete, this);*/

        L.Handler.prototype.disable.call(this);

        //this._map.fire('draw:deletestop', { handler: this.type });

        this.fire('disabled', { handler: this.type});
    },

    addHooks: function () {
        var map = this._map;

        if (map) {
            map.getContainer().focus();

            this._deletableLayers.eachLayer(this._enableLayerDelete, this);
            map.fire('draw:mapclear');

          /*  this._deletedLayers = new L.LayerGroup();

            this._tooltip = new L.Tooltip(this._map);
            this._tooltip.updateContent({ text: L.drawLocal.edit.handlers.remove.tooltip.text });

            this._map.on('mousemove', this._onMouseMove, this);*/
        }
    },

    removeHooks: function () {
        if (this._map) {
            this._deletedLayers = null;
            /*this._deletableLayers.eachLayer(this._disableLayerDelete, this);
            this._deletedLayers = null;

            this._tooltip.dispose();
            this._tooltip = null;

            this._map.off('mousemove', this._onMouseMove, this);*/
        }
    },

    revertLayers: function () {
        // Iterate of the deleted layers and add them back into the featureGroup
        this._deletedLayers.eachLayer(function (layer) {
            this._deletableLayers.addLayer(layer);
            layer.fire('revert-deleted', { layer: layer });
        }, this);
    },

    _enableLayerDelete: function (e) {
        var layer = e.layer || e.target || e;

        //layer.on('click', this._removeLayer, this);
        this._removeLayer(layer);
    },

 /*_disableLayerDelete: function (e) {
        var layer = e.layer || e.target || e;

        layer.off('click', this._removeLayer, this);

        // Remove from the deleted layers so we can't accidently revert if the user presses cancel
        this._deletedLayers.removeLayer(layer);
    },*/

    _removeLayer: function (e) {
        var layer = e.layer || e.target || e;

        this._deletableLayers.removeLayer(layer);

        //this._deletedLayers.addLayer(layer);

        layer.fire('deleted');
    },

   /* _onMouseMove: function (e) {
        this._tooltip.updatePosition(e.latlng);
    },
*/
    _hasAvailableLayers: function () {
        return this._deletableLayers.getLayers().length !== 0;
    }
});


