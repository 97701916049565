<template>
	 <div>
        <div style="position: fixed;z-index: 99999;width: 100%;height: 176px;background-color: #f2f2f2;">
            <headV />
        </div>
	<div style="width: 100%;position: absolute;top:176px;background-color: #f2f2f2;">
		<!-- content -->
		<div class="resource container">
			<div id="center" style=" width:960px; height:580px; padding-left:5px; margin-top:20px;">
				<div id="leftPanel">
					<div id="themeRes">
						<span id="themeResCh" style=" font-weight:bold">服务列表</span>
					</div>
					<div class="whiteline"></div>
					<ul style="padding-top:10px">
						<li id="sl" class="srvSelected">
							<a title="矢量地图服务"><span>矢量地图服务</span></a>
						</li>
						
						<li id="Raster">
							<a title="影像地图服务"><span>影像地图服务</span></a>
						</li>
						<li id="yxzj">
							<a title="影像图注记服务"><span>影像图注记服务</span></a>
						</li>
						<li id="poi">
							<a title="兴趣点查询服务"><span>兴趣点查询服务</span></a>
						</li>
						<li id="jyRespl">
							<a title="建筑物专题服务"><span>建筑物专题服务</span></a>
						</li>
						<li id="jysd">
							<a title="湿地专题服务"><span>湿地专题服务</span></a>
						</li>
					</ul>
				</div>
				<div id="rightPanel">
					<div id="mapDisplay">
						<service ref="maptype"  />
						<!-- :maptype="maptypeVal" -->
					</div>
				</div>
			</div>
		</div>
		<!-- footer -->
		<div class="footer_bg container" style="padding-top: 20px;height: 188px;">
			<div class="footer">
				<foot />
			</div>
		</div>
	</div>
</div>
</template>
<script setup>
import headV from "./head.vue"
import {ref,onMounted} from 'vue'
import foot from './footer.vue';
import service from './service.vue'
// import $ from 'jquery'
const maptype=ref(null)
onMounted(() => {
	$('#leftPanel li').bind("click", function () {
		$('li').removeClass('srvSelected');
		$(this).addClass('srvSelected');
		maptype.value.inimap($(this).attr("id"))//调用子组件的方法
	});
})
</script>
<style scoped>
@import url("../css/nav.css");
@import url("../css/index.css");
@import url("../css/resource.css");
</style>