<template>
  <div>
    <div
      style="
        position: fixed;
        z-index: 99999;
        width: 100%;
        height: 176px;
        background-color: #f2f2f2;
      "
    >
      <headV />
    </div>
    <div
      style="
        width: 100%;
        position: absolute;
        top: 176px;
        background-color: #f2f2f2;
      "
    >
      <div class="menu_map container">
        <div class="map_box row">
          <div id="mapDiv" class="col-lg-10 col-xm-12 col-lg-push-2">
            <div
              id="map-container"
              style="width: 300px; height: 300px; overflow: hidden"
            ></div>
          </div>
          <div class="col-lg-2 row col-lg-pull-10 map_box_nav">
            <div class="col-lg-12 col-md-2 col-sm-2 col-xs-4 detail">
              <a
                href="https://www.webmap.cn/store.do?method=store&storeId=1390"
                class="proser proser2"
                target="_blank"
                v-on:click="getcontent('成果目录')"
                >成果目录</a
              >
            </div>
            <!-- <div class="col-lg-12 col-md-2 col-sm-2 col-xs-4 detail">
						<a href="ztys.html" class="proser proser1" target="_blank" >专题要素</a>
					</div> -->
            <div class="col-lg-12 col-md-2 col-sm-2 col-xs-4 detail">
              <a
                href="http://lbs.tianditu.gov.cn/server/MapService.html"
                class="proser proser4"
                target="_blank"
                v-on:click="getcontent('地图API')"
                >地图API</a
              >
            </div>
            <div class="col-lg-12 col-md-2 col-sm-2 col-xs-4 detail">
              <a
                href="http://lbs.tianditu.gov.cn/server/guide.html"
                class="proser proser8"
                target="_blank"
                v-on:click="getcontent('WebAPI')"
                >WebAPI</a
              >
            </div>
            <div class="col-lg-12 col-md-2 col-sm-2 col-xs-4 detail">
              <a
                href="http://lbs.tianditu.gov.cn/data/dataapi.html"
                class="proser proser6"
                target="_blank"
                v-on:click="getcontent('数据API')"
                >数据API</a
              >
            </div>
            <div class="col-lg-12 col-md-2 col-sm-2 col-xs-4 detail">
              <!-- <router-link
                class="proser proser9"
                :to="{ path: '/resource' }"
                target="_blank"
                v-on:click="getcontent('服务列表')"
                >服务列表</router-link
              > -->
              <a href="#/resource" class="proser proser9" target="_blank" v-on:click="getcontent('服务列表')"
                >服务列表</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- 开发资源-->
      <div class="main">
        <div class="resource container">
          <div class="resource">
            <div class="resource_tit">
              <span>开发资源</span>
              <a href="http://service.tianditu.gov.cn/" target="_blank"
                >更多服务</a
              >
            </div>
            <div class="resource_con">
              <div class="api_con col-lg-9 col-md-9">
                <!-- 平板dom -->
                <nav class="navbar navbar-default blocks">
                  <div class="container-fluid">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="navbar-header">
                      <ul class="nav navbar-nav">
                        <li class="dropdown">
                          <a
                            href="javascript:void(0)"
                            data-target="api-resource"
                            class="dropdown-toggle"
                            id="actions"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            >地图 API</a
                          >
                        </li>
                        <li class="dropdown">
                          <a
                            href="javascript:void(0)"
                            data-target="map-resource"
                            class="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            >网页API</a
                          >
                        </li>
                        <li class="dropdown">
                          <a
                            href="javascript:void(0)"
                            data-target="update-resource"
                            class="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            >WEB服务API</a
                          >
                        </li>
                        <li class="dropdown">
                          <a
                            href="javascript:void(0)"
                            data-target="date-api"
                            class="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            >数据API</a
                          >
                        </li>
                        <li class="dropdown">
                          <a
                            href="javascript:void(0)"
                            data-target="mobile-sdk"
                            class="dropdown-toggle"
                            data-toggle="dropdown"
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            >移动SDK</a
                          >
                        </li>
                      </ul>
                    </div>
                    <!-- /.navbar-collapse -->
                  </div>
                  <!-- /.container-fluid -->
                </nav>
                <!-- 平板dom -->
                <div class="box_detail">
                  <div class="detail1" id="api-resource">
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/server/MapService.html"
                        target="_blank"
                        >矢量底图</a
                      >
                    </div>
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/server/MapService.html"
                        target="_blank"
                        >影像底图</a
                      >
                    </div>
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/server/MapService.html"
                        target="_blank"
                        >地形晕渲</a
                      >
                    </div>
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/server/MapService.html"
                        target="_blank"
                        >地名注记</a
                      >
                    </div>
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/server/MapService.html"
                        target="_blank"
                        >全球境界</a
                      >
                    </div>
                  </div>
                  <div class="detail2" id="map-resource">
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/api/js3.0/guide.html"
                        target="_blank"
                        >Javascript 3.0 API</a
                      >
                    </div>
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/api/js4.0/guide.html"
                        target="_blank"
                        >Javascript 4.0 API</a
                      >
                    </div>
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/api/js4.0/opensource/source.html"
                        target="_blank"
                        >Javascript 4.0 API 开源库</a
                      >
                    </div>
                  </div>
                  <div class="detail3" id="update-resource">
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/server/search.html"
                        target="_blank"
                        >POI搜索</a
                      >
                    </div>
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/server/drive.html"
                        target="_blank"
                        >驾车规划</a
                      >
                    </div>
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/server/bus.html"
                        target="_blank"
                        >公交规划</a
                      >
                    </div>
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/server/geocodinginterface.html"
                        target="_blank"
                        >地理编码查询</a
                      >
                    </div>
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/server/geocoding.html"
                        target="_blank"
                        >逆地理编码查询</a
                      >
                    </div>
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/server/administrative.html"
                        target="_blank"
                        >行政区划</a
                      >
                    </div>
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/staticapi/static.html"
                        target="_blank"
                        >静态地图API</a
                      >
                    </div>
                  </div>
                  <!--<div class="detail4" id="date-api">
									<div>
										<a href="http://lbs.tianditu.gov.cn/data/dataapi.html" target="_blank">交通要素</a>
									</div>
									<div>
										<a href="http://lbs.tianditu.gov.cn/data/dataapi.html" target="_blank">水系要素</a>
									</div>
									<div>
										<a href="http://lbs.tianditu.gov.cn/data/dataapi.html" target="_blank">居民地要素</a>
									</div>
								</div>-->
                  <div class="detail5" id="mobile-sdk">
                    <div>
                      <a
                        href="http://lbs.tianditu.gov.cn/android/guide.html"
                        target="_blank"
                        >Android地图SDK</a
                      >
                    </div>
                  </div>
                </div>
                <!-- 大屏 -->
                <ul class="col-lg-12 col-md-12 nones">
                  <li class="sourceli col-lg-2 col-md-2">
                    <span class="sourcespan"
                      ><a
                        href="http://lbs.tianditu.gov.cn/server/MapService.html"
                        target="_blank"
                        >地图 API</a
                      ><span class="devsour"></span
                    ></span>
                    <div class="detail">
                      <a
                        href="http://lbs.tianditu.gov.cn/server/MapService.html"
                        target="_blank"
                        >矢量底图</a
                      >
                      <a
                        href="http://lbs.tianditu.gov.cn/server/MapService.html"
                        target="_blank"
                        >影像底图</a
                      >
                      <a
                        href="http://lbs.tianditu.gov.cn/server/MapService.html"
                        target="_blank"
                        >地形晕渲</a
                      >
                      <a
                        href="http://lbs.tianditu.gov.cn/server/MapService.html"
                        target="_blank"
                        >地名注记</a
                      >
                      <a
                        href="http://lbs.tianditu.gov.cn/server/MapService.html"
                        target="_blank"
                        >全球境界</a
                      >
                    </div>
                  </li>
                  <li class="sourceli col-lg-3 col-md-3">
                    <span class="sourcespan"
                      ><a
                        href="http://lbs.tianditu.gov.cn/home.html"
                        target="_blank"
                        >网页API</a
                      ><span class="devsour"></span
                    ></span>
                    <div class="detail">
                      <a
                        href="http://lbs.tianditu.gov.cn/api/js3.0/guide.html"
                        target="_blank"
                        >JavaScript 3.0 API</a
                      >
                      <a
                        href="http://lbs.tianditu.gov.cn/api/js4.0/guide.html"
                        target="_blank"
                        >JavaScript 4.0 API</a
                      >
                      <a
                        href="http://lbs.tianditu.gov.cn/api/js4.0/opensource/source.html"
                        target="_blank"
                        >JavaScript 4.0 API 开源库</a
                      >
                    </div>
                  </li>
                  <li class="sourceli col-lg-3 col-md-3">
                    <span class="sourcespan"
                      ><a
                        href="http://lbs.tianditu.gov.cn/server/guide.html"
                        target="_blank"
                        >WEB服务API</a
                      ><span class="devsour"></span
                    ></span>
                    <div class="detail">
                      <a
                        href="http://lbs.tianditu.gov.cn/server/search.html"
                        target="_blank"
                        >POI搜索</a
                      >
                      <a
                        href="http://lbs.tianditu.gov.cn/server/drive.html"
                        target="_blank"
                        >驾车规划</a
                      >
                      <a
                        href="http://lbs.tianditu.gov.cn/server/bus.html"
                        target="_blank"
                        >公交规划</a
                      >
                      <a
                        href="http://lbs.tianditu.gov.cn/server/geocodinginterface.html"
                        target="_blank"
                        >地理编码查询</a
                      >
                      <a
                        href="http://lbs.tianditu.gov.cn/server/geocoding.html"
                        target="_blank"
                        >更多</a
                      >
                    </div>
                  </li>
                  <li class="sourceli col-lg-2 col-md-2">
                    <span class="sourcespan"
                      ><a
                        href="http://lbs.tianditu.gov.cn/data/dataapi.html"
                        target="_blank"
                        >数据API</a
                      ><span class="devsour"></span
                    ></span>
                    <div class="detail">
                      <!--<a href="https://service.tianditu.gov.cn/pages/info.html?sid=1435&type=info" target="_blank">政区要素</a>-->
                      <a
                        href="http://lbs.tianditu.gov.cn/data/dataapi.html"
                        target="_blank"
                        >交通要素</a
                      >
                      <a
                        href="http://lbs.tianditu.gov.cn/data/dataapi.html"
                        target="_blank"
                        >水系要素</a
                      >
                      <a
                        href="http://lbs.tianditu.gov.cn/data/dataapi.html"
                        target="_blank"
                        >居民地要素</a
                      >
                      <!-- <a href="./static/serchapi.html" target="_blank">地名地址</a>
										<a href="./static/dataSource.html" target="_blank">本地服务</a> -->
                      <!-- <router-link :to="{ path: '/Download' }" target="_blank"
                        >数据下载</router-link
                      > -->
                      <!-- <a href="#/Download" target="_blank">数据下载</a> -->
                      <!-- <a href="#/Upload" target="_blank">资源上传</a> -->
                      <!-- <router-link :to="{ path: '/Czgl' }" target="_blank"
                        >操作管理</router-link
                      > -->
                      <!-- <a href="#/Czgl" target="_blank">操作管理</a> -->
                      <!--<a href="./static/czgl.html" target="_blank">操作管理</a>-->
                      <!--<a href="http://58.217.53.63:6080/arcgis/rest/services" target="_blank">专题数据</a>-->
                    </div>
                  </li>
                  <!--<li class="sourceli col-lg-2 col-md-2">
										<span class="sourcespan"><a href="http://service.tianditu.gov.cn/" target="_blank">服务资源</a><span class="devsour"></span></span>
										<div class="detail">
											
										</div>
									</li>-->
                </ul>
              </div>
              <div class="exploit col-lg-3 col-md-3">
                <p>
                  为开发者提供应用程序开发接口和在线服务资源，可满足各类基于地理信息的应用开发需求。
                </p>
                <div class="developer">
                  <a href="https://console.tianditu.gov.cn" target="_blank"
                    >成为开发者</a
                  ><i></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 专题，典型 -->
        <div class="app container">
          <!-- 典型应用 -->
          <div class="application col-xs-12 col-sm-12 col-md-12">
            <h4><span>典型应用</span></h4>
            <div class="row">
              <div class="col-xs-12 col-sm-4 col-md-4">
                <a href="./static/jyjjzb/map/index.html" target="_blank">
                  <img :src="require('../images/sfyy/app1.png')" height="64" />
                  <div class="detail">
                    <h4>江阴市经济指标</h4>
                    <div class="tip"><span>点击查看详情</span><i></i></div>
                  </div>
                </a>
              </div>
            </div>
            <!-- <div class="row" style="margin-top: 15px;">
						<div class="col-xs-12 col-sm-4 col-md-4">
							<a href="./static/ShiFanYingYong/zjkongqi-fabuxitong.html" target="_blank">
								<img :src="require('../images/app/air.jpg')" height="64" />
								<div class="detail">
									<h4>空气质量</h4>
									<div class="tip">
										<span>点击查看详情</span><i></i>
									</div>
								</div>
							</a>
						</div>
						<div class="col-xs-12 col-sm-4 col-md-4">
							<a href="./static/ShiFanYingYong/nenghao-jianguan.html" target="_blank">
								<img :src="require('../images/app/jz.jpg')" height="64" />
								<div class="detail">
									<h4>建筑能耗</h4>
									<div class="tip">
										<span>点击查看详情</span><i></i>
									</div>
								</div>
							</a>
						</div>
						<div class="col-xs-12 col-sm-4 col-md-4">
							<a href="./static/ShiFanYingYong/tanpaifang.html" target="_blank">
								<img :src="require('../images/app/tpf.png')" height="64" />
								<div class="detail">
									<h4>碳排放</h4>
									<div class="tip">
										<span>点击查看详情</span><i></i>
									</div>
								</div>
							</a>
						</div>
					</div> -->
          </div>
        </div>
      </div>
      <!-- 底部版权 -->
      <div class="footer_bg container">
        <div class="footer">
          <foot />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import headV from "./head.vue";
import foot from "./footer.vue";
import { onMounted, onBeforeMount } from "vue";
import global from "../components/global.vue";
import switchLayer from "../js/map/switchLayer.js";
import UserToken from "../js/kmapService.js";
onMounted(() => {
  initMap();
  //CkeckedLogin();


});


function CkeckedLogin() {
  var uesertoken = UserToken.getUserToken("dsjzx", "8A2369EAF76DE1452987E48BC5C26A70");
  sessionStorage.setItem("uesertoken", uesertoken);
}

  





function initMap() {
  var map = L.map("mapDiv", {
    center: [global.centerY, global.centerX],
    maxZoom: 19,
    zoom: 9,
    crs: L.CRS.EPSG4326,
    attributionControl: false,
  });
  switchLayer.gettk(map);
  switchLayer.switchMap(map, {
    y: -135,
  });


}


//-------------------------widgetdj------------------------------------
var isn = returnCitySN;
//document.write("<span id='ip'>"+'您的IP：' + ip + '，归属地为：' + gsd +"</span>");
//时间
var i = new Date();
var h = i.getHours();
var m = i.getMinutes();
//日期 xxxx-xx-xx
var mydate = new Date();
var rq = "" + mydate.getFullYear() + "-";
if (mydate.getMonth() + 1 < 10) {
  var month = "0" + (mydate.getMonth() + 1) + "-";
} else {
  var month = mydate.getMonth() + 1 + "-";
}
rq += month;
if (mydate.getDate() < 10) {
  var day = "0" + mydate.getDate();
} else {
  var day = mydate.getDate();
}
rq += day;
//console.log("sss",rq)
//guid
function guid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
//用户点击模块记录信息

function getcontent(obj) {
  var widgetname = obj;
  console.log(widgetname);
 //insertwidgetfwsj(username, ip, wzmc, widgetname, rq, tid, gsd);
}

</script>
<style scoped>
@import url("../css/nav.css");
@import url("../css/index.css");
@import url("../css/map.css");
@import url("../css/indexresource.css");
@import url("../css/special.css");
@import url("../css/switchLayer.css");
@import url("../css/newIndex.css");
</style>
