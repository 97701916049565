<template>
	 <div>
        <div style="position: fixed;z-index: 99999;width: 100%;height: 176px;background-color: #f2f2f2;">
            <headV />
        </div>
		<div style="width: 100%;position: absolute;top:176px;background-color: #f2f2f2;">
		<!--内容-->
		<div class="resource container" style="padding-bottom: 20px;">
			<div style="height: 35px;font-size: 15px;">
				<span herf="" style="color: #595959;">您当前的位置:</span>
				<a href="http://jiangsu.tianditu.gov.cn/zhenjiang/" style="color:#666;">首页</a>
				<span style="color: #595959;font-weight: bold;">>>档案专题服务</span>
			</div>
			<div class="ana_content">
				<div class="ana_left">
					<div class="help_left_head">
						<img class="leftimg" src="./iw_minus.gif" onclick="changeItem(this.nextSibling)" />
						<a hidefocus href="javascript:void(0) " onclick="changeItem(this) ">历年政区图</a>
					</div>
					<ul class="help_left_list ">
						<li>
							<a hidefocus href="javascript:void(0) " onclick="changeIframeUrl( '2014年政区图', this) "><span>2014年政区图</span></a>
						</li>
						<li></li>
						<li>
							<a hidefocus href="javascript:void(0) " onclick="changeIframeUrl( '2017年政区图', this) "><span>2017年政区图</span></a>
						</li>
						<li>
							<a hidefocus href="javascript:void(0) " onclick="changeIframeUrl( '2020年政区图', this) "><span>2020年政区图</span></a>
						</li>
					</ul>
					<div class="help_left_head ">
						<img class="leftimg " src="./iw_minus.gif " onclick="changeItem(this.nextSibling)" />
						<a hidefocus href="javascript:void(0) " onclick="changeItem(this) ">历年城区图</a>
					</div>
					<ul class="help_left_list">
						<li>
							<a hidefocus href="javascript:void(0) " onclick="changeIframeUrl('2014年城区图', this) "><span>2014年城区图</span></a>
						</li>
						<li>
							<a hidefocus href="javascript:void(0) " onclick="changeIframeUrl('2017年城区图', this) "><span>2017年城区图</span></a>
						</li>
						<li>
							<a hidefocus href="javascript:void(0) " onclick="changeIframeUrl('2020年城区图', this) "><span>2020年城区图</span></a>
						</li>
					</ul>
					<div class="help_left_head">
						<img class="leftimg" src="./iw_minus.gif" onclick="changeItem(this.nextSibling) " />
						<a hidefocus href="javascript:void(0) " onclick="changeItem(this) ">历年旅游图</a>
					</div>
					<ul class="help_left_list ">
						<li>
							<a hidefocus href="javascript:void(0) " onclick="changeIframeUrl( '2015旅游图A面封面', this) "><span>2015旅游图A面封面</span></a>
						</li>
						<li>
							<a hidefocus href="javascript:void(0) " onclick="changeIframeUrl( '2015旅游图B面城区', this) "><span>2015旅游图B面城区</span></a>
						</li>
						<li>
							<a hidefocus href="javascript:void(0) " onclick="changeIframeUrl( '2016旅游图A面封面', this) "><span>2016旅游图A面封面</span></a>
						</li>
						<li>
							<a hidefocus href="javascript:void(0) " onclick="changeIframeUrl( '2016旅游图B面城区', this) "><span>2016旅游图B面城区</span></a>
						</li>
						<li>
							<a hidefocus href="javascript:void(0) " onclick="changeIframeUrl( '2017旅游图A面封面', this) "><span>2017旅游图A面封面</span></a>
						</li>
						<li>
							<a hidefocus href="javascript:void(0) " onclick="changeIframeUrl( '2017旅游图B面城区', this) "><span>2017旅游图B面城区</span></a>
						</li>
					</ul>
				</div>
				<div class="ana_left " style="width: 80%; ">
					<iframe id="showContent " name="showContentIframe" src="/SMap/browpng/store.html?name=2014年政区图 " frameborder="0 " style="width:100%;height:99% " scrolling="no " allowtransparency="true "></iframe>
					<div id="cover_div" class="cover_div ">
						<div class="busy_div ">
							<img style="float:left;width: 50px;height: 50px; " src="./wait.gif " />
							<div class="busy_font ">正在加载，请稍候...</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer_bg container">
			<div class="footer">
				<foot />
			</div>
		</div>
	</div>
</div>
</template>

<script setup>
	
	// import "../js/util.js";
	import headV from "../pages/head.vue"
import foot from "../pages/footer.vue"
	import {
		onMounted
	} from "vue";
	onMounted(() => {
		window.changeItem = changeItem;
		window.changeIframeUrl = changeIframeUrl;
	})
	function changeItem(obj) {
		if ($(obj).parent().next("ul ").css("display ") == "none ") {
			$(obj).parent().next("ul ").css("display ", "block ");
			$(obj).prev().attr("src ", "./iw_minus.gif ");
		} else {
			$(obj).parent().next("ul ").css("display ", "none ");
			$(obj).prev().attr("src ", "./iw_plus.gif ");
		}
	}
	function changeIframeUrl(url, obj) {
		$("#cover_div").show();
		$("ul> li >a > span").removeClass("liselect");
		$(obj).children("span").addClass("liselect");
		//$("#showContent").attr("src", "/SMap/browpng/store.html?name=" + url); 
		let iframe = window.frames['showContentIframe'];
		let iurl = "/SMap/browpng/store.html?name=" + url;
		iframe.onChangeStr(iurl,$("#cover_div "));

	}
</script>

<style scoped>
	/* @import "../lib/bootstrap/dist/css/bootstrap.min.css"; */
	/* @import "../css/nav.css"; */
	/* @import "../css/index.css"; */
	@import "./store.css";
</style>