<template>
  <div>
    <div id="footer">
      <div class="foot_support"> 
        <div class="fwl"></div>
        江阴市人民政府、江阴市自然资源和规划局主办&nbsp;&nbsp;&nbsp;江苏省测绘工程院承办
      </div>
      <div class="foot_copyright">
        审图号：苏B（2023）011号&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;备案号：<a href="https://www.miit.gov.cn/" target="_blank"
          style="font-weight: normal">苏ICP备1206352号</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;版权所有 翻版必究
      </div>
      <div class="foot_browser">
        建议最小分辨率：1024x768 建议浏览器：IE8以上、Chrome
        <div class="foot_a">
          <a hidefocus href="#/Disclaimer" target="_blank">免责声明</a>
        </div>
        <div class="foot_a1">
          <a hidefocus href="#/ContentUs" target="_blank">联系我们</a>
        </div>
      </div>
      <div id="version2" style="position: relative; left: -275px; top: -72px; width: 53px">
        <a href="https://bszs.conac.cn/sitename?method=show&id=F6BEFF4B1FC23951E05310291AAC29C8" target="_blank"><img
            :src="require('../images/s1.png')" /></a>
      </div>
    </div>
  </div>
</template>

<script setup>
$(document).ready(function () {


  //fwlcount(1);//江阴的ID是1 
});


//根据fwlid获取数据
function getxmbyfwlid(xmid) {
  var zb = {};
  var url = "http://101.132.131.9:5010/api/Fwltj/Getfwl?id=" + xmid;//.net接口

  //读取用户登录的记录
  $.ajax({
    type: "get",
    url: url,
    cache: false,
    async: false,
    dataType: "json",
    beforeSend: function (xhr) {
      xhr.setRequestHeader('Authorization', 'Bearer ');
    },
    success: function (result) {
      zb = result;

    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {
      //console.log("footer服务错误：" , textStatus);
    }
  });
  return zb;
}
//更新jrfwl
function setjrfwl(xmid, jrfwl) {
  var zb = {};
  $.ajax({
    type: "get",
    url: "http://101.132.131.9:5010/api/Fwltj/UpdataJrfwl?id=" + xmid + "&jrfwl=" + jrfwl,
    dataType: "json",
    success: function (result) { 
      zb = result;
      //console.log("asd2",zb)
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {
    }
  });
  return zb;
}


function setljfwl(xmid, ljfwl) {
  var zb = {};
  $.ajax({
    type: "get",
    url: "http://101.132.131.9:5010/api/Fwltj/UpdataLjfwl?id=" + xmid + "&ljfwl=" + ljfwl,
    async: false,
    dataType: "json",
    success: function (result) {
      zb = result;
      //console.log("asd3",zb)
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {
    }
  });
  return zb;
}


function fwlcount(i) {
  var zb = getxmbyfwlid(i);
  //console.log("asd",zb)
  var jrfwl = zb.jrfwl;
  var ljfwl = zb.ljfwl + 1;
  var fwrq = zb.fwrq.substring(0, 10)  //获取现存的访问量数据
  //console.log(fwrq) 
  var mydate = new Date();//xxxx-xx-xx
  var str = "" + mydate.getFullYear() + "-";
  if (mydate.getMonth() + 1 < 10) {
    var month = "0" + (mydate.getMonth() + 1) + "-"
  } else { month = (mydate.getMonth() + 1) + "-" }
  str += month;
  if (mydate.getDate() < 10) {
    var day = "0" + (mydate.getDate())
  } else { day = (mydate.getDate()) }
  str += day;
  if (fwrq == str) {   //与系统日期做判断
    jrfwl += 1;
    setjrfwl(i, jrfwl);
  }
  else {
    setjrfwl(i, 1)
    //setfwrq(i,str) //更新今日访问量数据+1，更新访问日期 
  }
  setljfwl(i, ljfwl); //更新累计访问量数据+1
  var zb1 = getxmbyfwlid(i);//产生修改后再读取新的访问量数据
  jrfwl = zb1.jrfwl
  ljfwl = zb1.ljfwl
  var $fwl = "<div class='fw'>" + "今日访问量：" + jrfwl + "，总访问量：" + ljfwl + "</div>"
  $(".fwl").append($fwl);
}
</script>
<style scoped>
@import url("../css/footer.css");
</style>