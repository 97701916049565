/**
 * Created by Administrator on 2014/12/22.
 */
L.GeometryUtil = L.extend(L.GeometryUtil || {}, {
    // Ported from the OpenLayers implementation. See https://github.com/openlayers/openlayers/blob/master/lib/OpenLayers/Geometry/LinearRing.js#L270
    geodesicArea: function (latLngs) {
        var pointsCount = latLngs.length,
            area = 0.0,
            d2r = L.LatLng.DEG_TO_RAD,
            p1, p2;
            d2r=0.017453292519943295;

        if (pointsCount > 2) {
            for (var i = 0; i < pointsCount; i++) {
                p1 = latLngs[i];
                p2 = latLngs[(i + 1) % pointsCount];
                area += ((p2.lng - p1.lng) * d2r) *
                    (2 + Math.sin(p1.lat * d2r) + Math.sin(p2.lat * d2r));
            }
            area = area * 6378137.0 * 6378137.0 / 2.0;
        }

        return Math.abs(area);
    },

    readableArea: function (area, isMetric) {
        var areaStr;

        if (isMetric) {
            if (area >= 1000000) {
                areaStr = (area * 0.000001).toFixed(2) + ' 平方公里';
            } else {
                areaStr = area.toFixed(2) + ' 平方米';
            }
        } else {
            area /= 0.836127; // Square yards in 1 meter

            if (area >= 3097600) { //3097600 square yards in 1 square mile
                areaStr = (area / 3097600).toFixed(2) + ' mi&sup2;';
            } else if (area >= 4840) {//48040 square yards in 1 acre
                areaStr = (area / 4840).toFixed(2) + ' acres';
            } else {
                areaStr = Math.ceil(area) + ' yd&sup2;';
            }
        }

        return areaStr;
    },

    readableDistance: function (distance, isMetric) {
        var distanceStr;

        if (isMetric) {
            // show metres when distance is < 1km, then show km
            if (distance > 1000) {
                distanceStr = (distance  / 1000).toFixed(2) + ' 公里';
            } else {
                distanceStr = Math.ceil(distance) + ' 米';
            }
        } else {
            distance *= 1.09361;

            if (distance > 1760) {
                distanceStr = (distance / 1760).toFixed(2) + ' miles';
            } else {
                distanceStr = Math.ceil(distance) + ' yd';
            }
        }

        return distanceStr;
    }
});

/*
 * L.LatLngUtil contains different utility functions for LatLngs.
 */

L.LatLngUtil = {
    // Clones a LatLngs[], returns [][]
    cloneLatLngs: function (latlngs) {
        var clone = [];
        for (var i = 0, l = latlngs.length; i < l; i++) {
            clone.push(this.cloneLatLng(latlngs[i]));
        }
        return clone;
    },

    cloneLatLng: function (latlng) {
        return L.latLng(latlng.lat, latlng.lng);
    }
};

L.Util.extend(L.LineUtil, {
    // Checks to see if two line segments intersect. Does not handle degenerate cases.
    // http://compgeom.cs.uiuc.edu/~jeffe/teaching/373/notes/x06-sweepline.pdf
    segmentsIntersect: function (/*Point*/ p, /*Point*/ p1, /*Point*/ p2, /*Point*/ p3) {
        return	this._checkCounterclockwise(p, p2, p3) !==
            this._checkCounterclockwise(p1, p2, p3) &&
            this._checkCounterclockwise(p, p1, p2) !==
            this._checkCounterclockwise(p, p1, p3);
    },

    // check to see if points are in counterclockwise order
    _checkCounterclockwise: function (/*Point*/ p, /*Point*/ p1, /*Point*/ p2) {
        return (p2.y - p.y) * (p1.x - p.x) > (p1.y - p.y) * (p2.x - p.x);
    }
});

L.Polygon.include({
    // Checks a polygon for any intersecting line segments. Ignores holes.
    intersects: function () {
        var polylineIntersects,
            points = this._originalPoints,
            len, firstPoint, lastPoint, maxIndex;

        if (this._tooFewPointsForIntersection()) {
            return false;
        }

        polylineIntersects = L.Polyline.prototype.intersects.call(this);

        // If already found an intersection don't need to check for any more.
        if (polylineIntersects) {
            return true;
        }

        len = points.length;
        firstPoint = points[0];
        lastPoint = points[len - 1];
        maxIndex = len - 2;

        // Check the line segment between last and first point. Don't need to check the first line segment (minIndex = 1)
        return this._lineSegmentsIntersectsRange(lastPoint, firstPoint, maxIndex, 1);
    }
});

L.Polyline.include({
    // Check to see if this polyline has any linesegments that intersect.
    // NOTE: does not support detecting intersection for degenerate cases.
    intersects: function () {
        var points = this._originalPoints,
            len = points ? points.length : 0,
            i, p, p1;

        if (this._tooFewPointsForIntersection()) {
            return false;
        }

        for (i = len - 1; i >= 3; i--) {
            p = points[i - 1];
            p1 = points[i];


            if (this._lineSegmentsIntersectsRange(p, p1, i - 2)) {
                return true;
            }
        }

        return false;
    },

    // Check for intersection if new latlng was added to this polyline.
    // NOTE: does not support detecting intersection for degenerate cases.
    newLatLngIntersects: function (latlng, skipFirst) {
        // Cannot check a polyline for intersecting lats/lngs when not added to the map
        if (!this._map) {
            return false;
        }

        return this.newPointIntersects(this._map.latLngToLayerPoint(latlng), skipFirst);
    },

    // Check for intersection if new point was added to this polyline.
    // newPoint must be a layer point.
    // NOTE: does not support detecting intersection for degenerate cases.
    newPointIntersects: function (newPoint, skipFirst) {
        var points = this._originalPoints,
            len = points ? points.length : 0,
            lastPoint = points ? points[len - 1] : null,
        // The previous previous line segment. Previous line segment doesn't need testing.
            maxIndex = len - 2;

        if (this._tooFewPointsForIntersection(1)) {
            return false;
        }

        return this._lineSegmentsIntersectsRange(lastPoint, newPoint, maxIndex, skipFirst ? 1 : 0);
    },

    // Polylines with 2 sides can only intersect in cases where points are collinear (we don't support detecting these).
    // Cannot have intersection when < 3 line segments (< 4 points)
    _tooFewPointsForIntersection: function (extraPoints) {
        var points = this._originalPoints,
            len = points ? points.length : 0;
        // Increment length by extraPoints if present
        len += extraPoints || 0;

        return !this._originalPoints || len <= 3;
    },

    // Checks a line segment intersections with any line segments before its predecessor.
    // Don't need to check the predecessor as will never intersect.
    _lineSegmentsIntersectsRange: function (p, p1, maxIndex, minIndex) {
        var points = this._originalPoints,
            p2, p3;

        minIndex = minIndex || 0;

        // Check all previous line segments (beside the immediately previous) for intersections
        for (var j = maxIndex; j > minIndex; j--) {
            p2 = points[j - 1];
            p3 = points[j];

            if (L.LineUtil.segmentsIntersect(p, p1, p2, p3)) {
                return true;
            }
        }

        return false;
    }
});

