<template>
  <div>
    <div
      style="
        position: fixed;
        z-index: 99999;
        width: 100%;
        height: 176px;
        background-color: #f2f2f2;
      "
    >
      <headV />
    </div>
    <div
      style="
        width: 100%;
        position: absolute;
        top: 176px;
        background-color: #f2f2f2;
      "
    >
      <!-- 下载资源-->
      <div class="main">
        <div class="resource container">
          <div class="resource">
            <div class="resource_tit">
              <span>下载资源</span>
            </div>
            <div>
              <table id="fresh-table">
                <thead>
                  <th data-field="id" data-sortable="true"  >序号</th>
                  <th data-field="sjnr">数据内容</th>
                  <th data-field="sjfl">数据分类</th>
                  <th data-field="blc">比例尺</th>
                  <th data-field="scsj" data-sortable="true">更新时间</th>
                  <th data-field="ksrq"  >操作</th>
                </thead>
                <tbody id="table" class="jstable">
                  <!-- <tr>
                    <td>1</td>
                    <td>Dakota Rice</td>
                    <td>$36,738</td>
                    <td>Niger</td>
                    <td>Oud-Turnhout</td>
                    <td>
                      <a href="园林绿化施工企业信用综合评价细则.doc">下载</a>
                    </td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- footer -->
      <div class="footer_bg container" style="padding-top: 20px; height: 188px">
        <div class="footer">
          <foot />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import headV from "./head.vue";
import { ref, onMounted, onBeforeMount } from "vue";
import foot from "./footer.vue";
import service from "./service.vue";
import "../js/bootstrap-table";   

const maptype = ref(null);

onMounted(() => {
  $("#leftPanel li").bind("click", function () {
    $("li").removeClass("srvSelected");
    $(this).addClass("srvSelected");
    maptype.value.inimap($(this).attr("id")); //调用子组件的方法
  });
  //==============
  settable();
  init();
  //
});
function init() {
  var $table = $("#fresh-table"),
    $alertBtn = $("#alertBtn"),
    full_screen = false,
    window_height;
  window_height = $(window).height();
  var table_height = window_height - 20;
 /*  $.get("http://101.132.131.9:5010/api/Xzzy_tdtjy/Getlist", function (res) { */
    //console.log(res)
    $table.bootstrapTable({
      toolbar: ".toolbar",
     /*  data:res , */
      showRefresh: false,
      search: true,
      showToggle: false,
      showColumns: false,
      pagination: true,
      striped: true,
      sortable: false, //是否排序
      height: table_height,
      pageSize: 25,
      pageList: [25, 50, 100],

      formatShowingRows: function (pageFrom, pageTo, totalRows) {
        //do nothing here, we don't want to show the text "showing x of y from..."
      },
      formatRecordsPerPage: function (pageNumber) {
        return pageNumber + " rows visible";
      },
      icons: {
        refresh: "fa fa-refresh",
        toggle: "fa fa-th-list",
        columns: "fa fa-columns",
        detailOpen: "fa fa-plus-circle",
        detailClose: "fa fa-minus-circle",
      },
    });
/*   }); */

  window.operateEvents = {
    "click .like": function (e, value, row, index) {
      alert("You click like icon, row: " + JSON.stringify(row));
      console.log(value, row, index);
    },
    "click .edit": function (e, value, row, index) {
      alert("You click edit icon, row: " + JSON.stringify(row));
      console.log(value, row, index);
    },
    "click .remove": function (e, value, row, index) {
      $table.bootstrapTable("remove", {
        field: "id",
        values: [row.id],
      });
    },
  };

  $alertBtn.click(function () {
    alert("You pressed on Alert");
  });

  $(window).resize(function () {
    $table.bootstrapTable("resetView");
  });
  function operateFormatter(value, row, index) {
    return [
      '<a rel="tooltip" title="Like" class="table-action like" href="javascript:void(0)" title="Like">',
      '<i class="fa fa-heart"></i>',
      "</a>",
      '<a rel="tooltip" title="Edit" class="table-action edit" href="javascript:void(0)" title="Edit">',
      '<i class="fa fa-edit"></i>',
      "</a>",
      '<a rel="tooltip" title="Remove" class="table-action remove" href="javascript:void(0)" title="Remove">',
      '<i class="fa fa-remove"></i>',
      "</a>",
    ].join("");
  }
}

var jg;
function Getlist() {
  $.ajax({
    type: "get",
    url: "http://101.132.131.9:5010/api/Xzzy_tdtjy/Getlist",
    data: {},
    async: false,
    dataType: "json",
    success: function (result) {
      jg = result;
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {},
  });
  return jg;
}
function settable() {
  $("#table").empty("");
  var zb = Getlist();
  var $tr = "";

  for (var i = 0; i < zb.length; i++) {
    // $button = "<button class='sc' id="+zb[i].id+">"+'删除'+"</button>";
    var j = i + 1;
    var rq = zb[i].scsj.substring(0, 9);
    var xzUrl = "http://101.132.131.9:5010/TDTzyxz/zyxz_tdtjy/" + zb[i].xzdz;//iis发布的文件夹路径
    $tr =
      "<tr>" +
      "<td>" +
      j +
      "</td>" +
      "<td>" +
      zb[i].sjnr +
      "</td>" +
      "<td>" +
      zb[i].sjfl +
      "</td>" +
      "<td>" +
      zb[i].blc +
      "</td>" +
      "<td>" +
      rq +
      "</td>" +
      "<td><a href=" +
      xzUrl +
      " onclick='djcs(" +
      zb[i].id +
      ")'>下载</a></td>" +
      "</tr>";
    $("#table").append($tr);
  }
}
window.djcs = (id) => {
  $.ajax({
    type: "post",
    url: "http://101.132.131.9:5010/api/Xzzy_tdtjy/UpdataXzcs?id=" + id,
    async: false,
    success: function (result) {
      console.log("sw", result.xzcs);
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {
      console.log("djcs:" + XMLHttpRequest, textStatus, errorThrown);
    },
  });
};
</script>
<style scoped>
/* @import url("../css/nav.css");
@import url("../css/index.css");
@import url("../css/resource.css"); */
@import url("../css/fresh-bootstrap-table.css");
 
 
</style>