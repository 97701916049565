import { tiledMapLayer } from "esri-leaflet";
import global from "../../components/global"
import $ from "jquery";
//var $ = $ || window;



var thishost = window.location.host;
let tk = null;

function gettk(map) {
	// $.ajax({
	// 	type: "get",
	// 	url: "http://221.228.70.166:28080/RemoteTokenServer",
	// 	jsonp: "callback",
	// 	dataType: "jsonp",
	// 	data: {
	// 		request: "getToken",
	// 		username: "jyzgj",
	// 		password: "Jyzgj@2023",
	// 		clientid: "ref." + thishost,
	// 		expiration: "300",
	// 		format: "json",
	// 	},
	// 	success: function (data) {
	// 		tk = data.token;
	// 		sessionStorage.setItem("tk", tk);
	// 		//console.log("sw", tk);
	// 		var jjLayer = new tiledMapLayer({
	// 			url: global.jj,
	// 			token: tk
	// 		}).addTo(map);
			
	// 	},
	// 	error: function (XMLHttpRequest, textStatus, errorThrown) { },
	// });

	$.ajax({
		type: "get",
		url: "http://smartcity1.mapjiangyin.com/Token/RemoteTokenServerWithoutPwd?gispwd=GisToken",
		async: false,
		dataType: "json",
		data: {
			
		},
		success: function (data) {
			tk = data.data;
			sessionStorage.setItem("tk", tk);
			//console.log("sw", tk);
			
			
		},
		error: function (XMLHttpRequest, textStatus, errorThrown) { },
	});


	return tk;
}

function switchMap(map, option) {

	// 图幅号控件类
	var SheetControl = L.Control.extend({
		initialize: function (options) {
			this._options = options;
			L.Util.setOptions(this, options);
		},
		onAdd: function (map) {
			var container = L.DomUtil.create('div', 'sheet-control', map.getContainer());
			var color;
			if (this._options.maptype == 'vec' || this._options.maptype == 'ter') {
				color = '#000';
			} else if (this._options.maptype == 'img') {
				color = '#fff';
			}
			container.innerHTML = '<img src="https://api.tianditu.gov.cn/v4.0/image/logo.png" width="53px" height="22px" opacity="0"><div class="sheetNumber" style="position:absolute;bottom:0px;left:58px;white-space:nowrap;color:' + color + ';">' + global.sheetNumber + '</div>';
			return container;
		}
	});

	var sheetControl;

	var layerControl = new L.Control({
		position: 'topright'
	});

	layerControl.onAdd = function () {
		var layerPop = document.createElement('div');
		layerPop.className = "layer_popup";
		var dtimg = require('../../js/map/vec.jpg');
		var yximg = require('../../js/map/img.jpg');
		var dximg = require('../../js/map/ter.jpg');
		layerPop.innerHTML = '<div class="layer_switch"><i></i></div>' +
			'<div class="layer_panel inactive">' +
			'	<i class="close"></i>' +
			'  <h3>选择底图</h3>' +
			'  <div class="layer-items">' +
			'		<a href="javascript:void(0);" id="vec_type"><img src=' + dtimg + '>地图</a>' +
			'		<a href="javascript:void(0);" id="img_type" style="margin: 0 16px"><img src=' + yximg + '>影像</a>' +
			'		<a href="javascript:void(0);" id="ter_type"><img src=' + dximg + '>地形</a>' +
			'  </div>' +
			'  <ul>' +
			'		<li><span>地名</span><i id="cn_name" class="active"></i></li>'
		'</ul>' +
			'</div>';
		return layerPop;
	}

	layerControl.onRemove = function (map) {
		//移除控件时要释放
	}

	map.addControl(layerControl);
	//添加回到视域按钮
	var defaultextentcontrol = new L.Control({
		position: 'topleft'
	});
	defaultextentcontrol.onAdd = function (map) {
		var layerPop = document.createElement('div');
		layerPop.className = "layer_popup";
		layerPop.innerHTML = '<a title="初始范围"><div class="extent_switch"><i></i></div></a>';
		return layerPop;
	}
	map.addControl(defaultextentcontrol);
	// 初始范围
	var initExtent = document.querySelector('.extent_switch');
	initExtent.addEventListener('click', function () {
		map.setView([global.centerY, global.centerX], 9);
		map.setMaxZoom(19);

	});

	function initExtent(_map) {
		_map.setView([global.centerY, global.centerX], 9);
		_map.setMaxZoom(19);
	}

	// 图例名称
	var legendControl = new L.Control({
		position: 'bottomright'
	});

	legendControl.onAdd = function () {
		var legend = document.createElement('div');
		legend.className = "legend_name";
		// legend.innerHTML = '<i></i>天地图访问分布';
		return legend;
	}

	legendControl.onRemove = function (map) {
		//移除控件时要释放
	}

	map.addControl(legendControl);

	var x = 10,
		y = 10;

	if (option) {
		if (option.x) {
			x = option.x;
		}

		if (option.y) {
			y = option.y;
		}
	}

	// layerControl.setOffset({x:-x,y:-y});

	var layerPanel = document.querySelector('.layer_panel');
	// 控制底图选择显隐
	var layerSwitch = document.querySelector('.layer_switch');
	layerSwitch.addEventListener('click', function () {
		toggleClass(layerPanel, 'inactive');
	});

	// 关闭底图
	var layerClose = document.querySelector('i.close');
	layerClose.addEventListener('click', function () {
		addClass(layerPanel, 'inactive');
	});

	var mapTypes = document.querySelectorAll('.layer-items a');

	var cnName = document.getElementById('cn_name');

	var cacheLayers = [];
	// 切换底图
	for (var i = 0; i < mapTypes.length; i++) {
		var mapType = mapTypes[i];

		mapType.addEventListener('click', mapTypeClick.bind(mapType));
	}

	function mapTypeClick() {
		var target = this;
		if (target.className.indexOf('active') > -1) {
			return;
		}

		remove();
		for (var m = 0; m < mapTypes.length; m++) {
			removeClass(mapTypes[m], 'active');
		}
		addClass(target, 'active');
		
		// 移除前图层
		for (var c = 0; c < cacheLayers.length; c++) {
			map.removeLayer(cacheLayers[c]);
		}
		// 清空缓存图层
		cacheLayers = [];

		var id = target.id,
			mapType;
		console.log("切换底图", id)
		if (id.indexOf('vec') == 0) {
			mapType = 'vec';
			// 矢量底图
			var normalm = L.tileLayer('http://t3.tianditu.gov.cn/vec_c/wmts?layer=vec&style=default&tilematrixset=c&Service=WMTS&Request=GetTile&Version=1.0.0&Format=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=b9b7eba8bdf41f268f354c38d1c8e088', {
				maxZoom: 16,
				minZoom: 1,
				zoomOffset: 1
			});
			var normala = L.tileLayer('http://t0.tianditu.gov.cn/cva_c/wmts?layer=cva&style=default&tilematrixset=c&Service=WMTS&Request=GetTile&Version=1.0.0&Format=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=b9b7eba8bdf41f268f354c38d1c8e088', {
				maxZoom: 16,
				minZoom: 1,
				zoomOffset: 1
			});
			tk = sessionStorage.getItem("tk")
			console.log("vec", tk)
			var scyNormalm = tiledMapLayer({
				url: global.sldt_jy,
				token: tk,
				maxZoom: 19,
				minZoom: 17
			})

			var scyNormala = tiledMapLayer({
				url: global.sldt_zj,
				token: tk,
				maxZoom: 19,
				minZoom: 17
			})
	 

			map.addLayer(normalm);
			cacheLayers.push(normalm);
			if (cnName.className.indexOf('active') > -1)
				map.addLayer(normala);
			cacheLayers.push(normala);
			map.addLayer(scyNormalm);
			cacheLayers.push(scyNormalm);
			if (cnName.className.indexOf('active') > -1)
				map.addLayer(scyNormala);
			cacheLayers.push(scyNormala);

			var jjLayer = new tiledMapLayer({
				url: global.jj,
				token: tk
			}).addTo(map);

		} 
		else if (id.indexOf('img') == 0) {
			mapType = 'img';
			
			tk = sessionStorage.getItem("tk")
			var imgm = L.tileLayer('http://t0.tianditu.gov.cn/img_c/wmts?layer=img&style=default&tilematrixset=c&Service=WMTS&Request=GetTile&Version=1.0.0&Format=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=b9b7eba8bdf41f268f354c38d1c8e088', {
				maxZoom: 16,
				minZoom: 1,
				zoomOffset: 1
			});
			var imga = L.tileLayer('http://t0.tianditu.gov.cn/cia_c/wmts?layer=cia&style=default&tilematrixset=c&Service=WMTS&Request=GetTile&Version=1.0.0&Format=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=b9b7eba8bdf41f268f354c38d1c8e088', {
				maxZoom: 16,
				minZoom: 1,
				zoomOffset: 1
			}),

				scyImgm = new tiledMapLayer({
					url: global.yxdt_jy,
					token: tk,
					maxZoom: 19,
					minZoom: 17
				}),
				scyImga = new tiledMapLayer({
					url: global,
					url: global.yxzj_jy,
					token: tk,
					maxZoom: 19,
					minZoom: 17
				});

				

			map.addLayer(imgm);
			cacheLayers.push(imgm);
			if (cnName.className.indexOf('active') > -1)
				map.addLayer(imga);
			cacheLayers.push(imga);
			map.addLayer(scyImgm);
			cacheLayers.push(scyImgm);
			if (cnName.className.indexOf('active') > -1)
				map.addLayer(scyImga);
			cacheLayers.push(scyImga);
			var jjLayer = new tiledMapLayer({
				url: global.jj,
				token: tk
			}).addTo(map);

		} else if (id.indexOf('ter') == 0) {
			mapType = 'ter';
			var Terrainm = L.tileLayer('http://t0.tianditu.gov.cn/ter_c/wmts?layer=ter&style=default&tilematrixset=c&Service=WMTS&Request=GetTile&Version=1.0.0&Format=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=b9b7eba8bdf41f268f354c38d1c8e088', {
				maxZoom: 16,
				minZoom: 1,
				zoomOffset: 1
			});
			var Terraina = L.tileLayer('http://t0.tianditu.gov.cn/cta_c/wmts?layer=cta&style=default&tilematrixset=c&Service=WMTS&Request=GetTile&Version=1.0.0&Format=tiles&TileMatrix={z}&TileCol={x}&TileRow={y}&tk=b9b7eba8bdf41f268f354c38d1c8e088', {
				maxZoom: 16,
				minZoom: 1,
				zoomOffset: 1
			});

			map.addLayer(Terrainm);
			cacheLayers.push(Terrainm);
			if (cnName.className.indexOf('active') > -1)
				map.addLayer(Terraina);
			cacheLayers.push(Terraina);
			var jjLayer = new tiledMapLayer({
				url: global.jj,
				token: tk
			}).addTo(map);
		}


		if (sheetControl) {
			map.removeControl(sheetControl);
		}
		// 添加图幅号
		sheetControl = new SheetControl({
			position: 'bottomleft',
			maptype: mapType
		});
		map.addControl(sheetControl);
	};

	// 切换注记
	cnName.addEventListener('click', function () {
		toggleClass(this, 'active');
		var tagLayer = cacheLayers[cacheLayers.length - 1];

		var tagLayer1 = cacheLayers[cacheLayers.length - 3];

		if (this.className.indexOf("active") > -1) {
      map.addLayer(tagLayer);
      if (cacheLayers.length > 2) map.addLayer(tagLayer1);
    } else {
      map.removeLayer(tagLayer);
      if (cacheLayers.length > 2) map.removeLayer(tagLayer1);
    }
	});

	/*function addLayer(mapType, check) {
		var mapZoom = 18,
			layerZoom = 18;
		// 地形
		if(mapType == 'ter') {
			mapZoom = 14;
			layerZoom = 14;
		}
		if(mapType == 'ibo' || mapType == 'tbo') {
			layerZoom = 10;
		}
		
		var mapLayer = L.tileLayer.TianDiTu(mapType, {
			maxZoom: 16,
			minZoom: 1
		});
		// 添加新的图层
		var check = cnName.className.indexOf('active') > -1;

		if(mapType.indexOf('c') != 0 || check) {
			map.addLayer(mapLayer);

			// 切换底图时保证底图和注记一直在最下面
			if(mapType == 'vec' || mapType == 'img' || mapType == 'ter') {
				mapLayer.setZIndex(-1);
			} else {
				mapLayer.setZIndex(0);
			}
		}
		map.setMinZoom(1);
		map.setMaxZoom(mapZoom);
		return mapLayer;
	}*/

	function remove() {
		for (var c = 0; c < cacheLayers.length; c++) {
			debugger
			var layer = cacheLayers[c];
			map.removeLayer(layer);
              console.log("remove", layer.url);		}
		document.querySelector('.layer_popup').style.display = "none";
	}

	function restore() {
		for (var c = 0; c < cacheLayers.length; c++) {
			var layer = cacheLayers[c];
			map.addLayer(layer);
			layer.setZIndex(c - cacheLayers.length + 1);
		}
		document.querySelector('.layer_popup').style.display = "block";
	}

	/**
	 * dom元素添加类
	 * @param obj
	 * @param cls
	 */
	function addClass(obj, cls) {
		var obj_class = obj.className, //获取 class 内容.
			blank = (obj_class != '') ? ' ' : ''; //判断获取到的 class 是否为空, 如果不为空在前面加个'空格'.
		var added = obj_class + blank + cls; //组合原来的 class 和需要添加的 class.
		obj.className = added; //替换原来的 class.
	}

	/**
	 * dom元素移除类
	 * @param obj
	 * @param cls
	 */
	function removeClass(obj, cls) {
		var obj_class = ' ' + obj.className + ' '; //获取 class 内容, 并在首尾各加一个空格. ex) 'abc    bcd' -> ' abc    bcd '
		obj_class = obj_class.replace(/(\s+)/gi, ' '), //将多余的空字符替换成一个空格. ex) ' abc    bcd ' -> ' abc bcd '
			removed = obj_class.replace(' ' + cls + ' ', ' '); //在原来的 class 替换掉首尾加了空格的 class. ex) ' abc bcd ' -> 'bcd '
		var removed = removed.replace(/(^\s+)|(\s+$)/g, ''); //去掉首尾空格. ex) 'bcd ' -> 'bcd'
		obj.className = removed; //替换原来的 class.
	}

	function toggleClass(obj, cls) {
		if (obj.className.indexOf(cls) > -1) {
			obj.className = obj.className.replace(cls, '');
		} else {
			var lastChar = obj.className.substr(-1);
			if (lastChar == ' ') {
				obj.className = obj.className + cls;
			} else {
				obj.className = obj.className + ' ' + cls;
			}
		}
	}

	// 叠加默认底图
	mapTypeClick.call(mapTypes[0]);

	this.remove = remove;
	this.restore = restore;
}


function getUserToken (username, pwd) {
	var zb = {};
	$.ajax({
	  type: "get",
	  url: "http://101.132.131.9:8088/api/ThirdLogin/GetUserInfoByPsw2",
	 
	  data: {
		username: username,
		pwd: pwd
	  },
	  async: false,
	  dataType: "json",
	  success: function (result) {
		var zb = result.data;
		console.log(zb);
	  },
	  error: function (XMLHttpRequest, textStatus, errorThrown) { },
	});
  
	return zb;
  }


export default {
	switchMap,
	gettk,
	getUserToken,
}