<template>
  <!--查看-->
  <div id="edit" style="margin-top: 10px">
    <form class="layui-form lay-select" action="">
      <div class="layui-form-item">
        <div class="layui-inline">
          <label class="layui-form-label" style="margin-right: 5px;width: 110px;"
            >数据内容:</label
          >
          <div class="layui-input-inline">
            <input
              id="sjnr"
              type="text"
              name=""
              autocomplete="off"
              class="layui-input"
            />
          </div>
        </div>
        <div class="layui-inline">
          <label class="layui-form-label" style="margin-right: 5px;width: 110px;"
            >数据分类:</label
          >
          <div class="layui-input-inline">
            <input
              id="sjfl"
              type="text"
              name=""
              autocomplete="new-password"
              class="layui-input"
            />
          </div>
        </div>
        <div class="layui-inline">
          <label class="layui-form-label" style="margin-right: 5px;width: 110px;"
            >比例尺:</label
          >
          <div class="layui-input-inline">
            <input
              id="blc"
              type="text"
              name=""
              autocomplete="off"
              class="layui-input"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script setup>
var form;
var $;

layui.use(["form"], function () {
  form = layui.form;
  $ = layui.$;
  $("#sjnr").val("");
  $("#sjfl").val("");
  $("#blc").val("");
  window.passUpdateVal = function () {
    var sjnr = $("#sjnr").val();
    var sjfl = $("#sjfl").val();
    var blc = $("#blc").val();

    if (sjnr == null || sjnr == "") {
      layer.tips("数据内容不能为空！", "#sjnr", {
        time: 1000,
        tips: 3,
      });
      return "-1";
    }
    if (sjfl == null || sjfl == "") {
      layer.tips("数据分类不能为空！", "#sjfl", {
        time: 1000,
        tips: 3,
      });
      return "-1";
    }
    if (blc == null || blc == "") {
      layer.tips("比例尺不能为空！", "#blc", {
        time: 1000,
        tips: 3,
      });
      return "-1";
    }
    var obj = {
      sjnr: sjnr,
      sjfl: sjfl,
      blc: blc,
    };

    return obj;
  };
});
</script>
<style scoped>
.layui-form-item .layui-input-inline {
  width: 230px;
}

.layui-form-radio {
  margin: 2px 10px 0 0;
}

#edit {
  padding-left: 15px;
}

.layui-form-label {
  color: black;
}

.layui-input,
.layui-select,
.layui-textarea {
  background-color: white;
}

.layui-input,
.layui-textarea {
  color: black;
  border-color: gray;
}

.layui-inline {
  margin-top: 20px;
}
</style>