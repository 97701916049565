import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import homeView from '../pages/home'
import Map from '../App.vue'
import resourceView from '../pages/resource.vue'
import exampleView from '../pages/ExamplePage.vue'
import contentUsView from '../pages/ContentUs.vue'
import disclaimerView from '../pages/Disclaimer.vue'
import updateLogView from '../pages/updateLog.vue'
import DownloadCentreView from '../pages/DownloadCentre.vue'
import docSoreView from '../SMap/docSore.vue'
import download from '../pages/Download.vue'
import czgl from '../pages/Czgl.vue'
import upload from '../pages/Upload.vue'
import userinfoEdit from '../pages/UserinfoEdit.vue'
import hxEmap from '../pages/emap.vue'
const routes = [
    {
        path: "/",
        component: homeView
    },

    /* {
        path: "/Emap",
        component: Map
    }, */
    {
        path: "/resource",
        component: resourceView
    },
    {
        path: "/ExamplePage",
        component: exampleView
    },
    {
        path: "/ContentUs",
        component: contentUsView
    },
    {
        path: "/Disclaimer",
        component: disclaimerView
    },
    {
        path: "/updateLog",
        component: updateLogView
    },
    {
        path: "/DownloadCentre",
        component: DownloadCentreView
    },
    {
        path: "/docSore",
        component: docSoreView
    },
    {
        path: "/Download",
        component: download
    },
    {
        path: "/Upload",
        component: upload
    }, {
        path: "/UserinfoEdit",
        component: userinfoEdit
    }, {
        path: "/Czgl",
        component: czgl
    }
]



const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router;


