<template>
  <div style="width: 680px; height: 545px; margin: 0">
    <div id="mapdiv">
      <div
        id="mapid"
        style="width: 680px; height: 546px; border: 1px solid #000"
      ></div>
    </div>

    <div class="desc" id="fwmessage">
      <div id="desctxt" class="block">
        <div>
          <span>服务名称：</span><span id="name"></span>
          <span>&nbsp;&nbsp;&nbsp;服务类型：</span><span id="type"></span>
          <img
            style="float: right; margin-right: 5px; cursor: pointer"
            id="hide"
            @click="hidemessage('hide')"
            :src="require('../images/zyzz/show_down.png')"
            height="30"
            width="30"
            alt=""
          />
        </div>
        <div><span>空间参考：</span><span id="spatialRef"></span></div>
        <div>
          <span>服务地址：</span
          ><span
            id="address"
            style="color: Red; font-weight: bold; cursor: pointer"
            @click="openYSJ()"
          ></span>
        </div>
        <div><span>服务摘要：</span><span id="abstract"></span></div>
        <div><span>版权所有：</span><span id="copyright"></span></div>
        <div></div>
      </div>
      <img
        alt=""
        id="show"
        width="30"
        height="30"
        @click="hidemessage('show')"
        style="float: right; margin-right: 5px; cursor: pointer; display: none"
        :src="require('../images/zyzz/show_up.png')"
      />
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, defineExpose } from "vue";
import global from "../components/global.vue";
import { featureLayer,dynamicMapLayer, tiledMapLayer } from "esri-leaflet";
import $ from "jquery";

let myMap;
var orderDoc = getXmlFile();
var mylx;
var thishost = window.location.host;
var tk;
onMounted(() => {
  myMap = L.map("mapid", {
    center: {
      lon: 120.296023,
      lat: 31.920433,
    },
    zoom: 11,
    crs: L.CRS.EPSG4326,
    zoomControl: true,
    attributionControl: false,
  });

  $.ajax({
    type: "get",
		url: "http://smartcity1.mapjiangyin.com/Token/RemoteTokenServerWithoutPwd?gispwd=GisToken",
		async: false,
		dataType: "json",
		data: {
			
		},
    success: function (data) {
      tk = data.data;
      //console.log("asd", tk);
      inimap("sl");
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {
      tk = "";
      //alert("请求失败！");
    },
  });
});

function inimap(lx) {
  var tilepane = myMap.getPane("tilePane");
  tilepane.innerHTML = "";
  // var overlaypane=myMap.getPane('overlayPane')
  // overlaypane.innerHTML = "";
  myMap.eachLayer(function (layer) {
    myMap.removeLayer(layer); //仅移除了动态图层
  });

  mylx = lx;
  switch (lx) {
    case "sl":
      var portlandParcels1 = new tiledMapLayer({
        url: global.sldt_jy,
        token: tk,
        maxZoom: 19,
        minZoom: 10,
      }).addTo(myMap);
      myMap.setZoom(18);
      $("#name").text(getXmlEle(lx, "fwmc"));
      $("#type").text(getXmlEle(lx, "fwlx"));
      $("#spatialRef").text(getXmlEle(lx, "kjck"));
      $("#abstract").text(getXmlEle(lx, "fwzy"));
      $("#address").text(getXmlEle(lx, "fwdzxq"));
      //			$("#address").text(sldt);
      $("#copyright").text(getXmlEle(lx, "bqsy"));
      break;
   
    case "Raster":
      var portlandParcels1 = new tiledMapLayer({
        url: global.yxdt_jy,
        token: tk,
        maxZoom: 20,
        minZoom: 10,
      }).addTo(myMap);
      myMap.setZoom(18);
      $("#name").text(getXmlEle(lx, "fwmc"));
      $("#type").text(getXmlEle(lx, "fwlx"));
      $("#spatialRef").text(getXmlEle(lx, "kjck"));
      $("#abstract").text(getXmlEle(lx, "fwzy"));
      $("#address").text(getXmlEle(lx, "fwdzxq"));
      $("#copyright").text(getXmlEle(lx, "bqsy"));
      break;
    case "yxzj":
      var portlandParcels1 = new tiledMapLayer({
        url: global.yxzj_jy,
        token: tk,
        maxZoom: 19,
        minZoom: 10,
      }).addTo(myMap);
      myMap.setZoom(18);
      $("#name").text(getXmlEle(lx, "fwmc"));
      $("#type").text(getXmlEle(lx, "fwlx"));
      $("#spatialRef").text(getXmlEle(lx, "kjck"));
      $("#abstract").text(getXmlEle(lx, "fwzy"));
      $("#address").text(getXmlEle(lx, "fwdzxq"));
      //			$("#address").text(yxzj);
      $("#copyright").text(getXmlEle(lx, "bqsy"));
      break;
    case "jyRespl":
      var portlandParcels5 = new featureLayer({
        token: tk,
        url: global.jyRespl,
        opacity: 1.0,
      }).addTo(myMap);
      myMap.setZoom(17);
      $("#name").text(getXmlEle(lx, "fwmc"));
      $("#type").text(getXmlEle(lx, "fwlx"));
      $("#spatialRef").text(getXmlEle(lx, "kjck"));
      $("#abstract").text(getXmlEle(lx, "fwzy"));
      $("#address").text(getXmlEle(lx, "fwdzxq"));
      //$("#address").text(yxzj);
      $("#copyright").text(getXmlEle(lx, "bqsy"));
      break;
      case "jysd":
      var portlandParcels5 = new featureLayer({
        token: tk,

        url: global.jysd,
        opacity: 1.0,
      }).addTo(myMap);
      myMap.setZoom(10);
      $("#name").text(getXmlEle(lx, "fwmc"));
      $("#type").text(getXmlEle(lx, "fwlx"));
      $("#spatialRef").text(getXmlEle(lx, "kjck"));
      $("#abstract").text(getXmlEle(lx, "fwzy"));
      $("#address").text(getXmlEle(lx, "fwdzxq"));
      //$("#address").text(yxzj);
      $("#copyright").text(getXmlEle(lx, "bqsy"));
      break;
    case "poi":
      var portlandParcels5 = new featureLayer({
        token: tk,

        url: global.poi_jy,
        opacity: 1.0,
      }).addTo(myMap);

      $("#name").text(getXmlEle(lx, "fwmc"));
      $("#type").text(getXmlEle(lx, "fwlx"));
      $("#spatialRef").text(getXmlEle(lx, "kjck"));
      $("#abstract").text(getXmlEle(lx, "fwzy"));
      $("#address").text(getXmlEle(lx, "fwdzxq"));
      $("#copyright").text(getXmlEle(lx, "bqsy"));
      break;
   
   
  }
}
defineExpose({ inimap }); //向父组件暴露需要修改的值
var identifyBrowser = function () {
  var cBrowser;
  if (
    window.navigator.userAgent.indexOf("Safari") >= 0 &&
    navigator.userAgent.toLowerCase().indexOf("version") >= 0
  ) {
    cBrowser = "safari";
  } else if (window.navigator.userAgent.indexOf("Chrome") >= 0) {
    cBrowser = "chrome";
  } else if (navigator.userAgent.toLowerCase().indexOf("msie") >= 0) {
    cBrowser = "ie";
  } else if (navigator.userAgent.toLowerCase().indexOf("firefox") >= 0) {
    cBrowser = "firefox";
  } else if (navigator.userAgent.toLowerCase().indexOf("opera") >= 0) {
    cBrowser = "opera";
  }
  return cBrowser;
};

function hidemessage(id) {
  switch (id) {
    case "hide":
      $("#desctxt").slideUp(1500, function () {
        $("#show").slideDown(1000);
        //$("#show").show();
        //$("#desctxt").hide();
      });
      break;
    case "show":
      $("#desctxt").slideDown(1500, function () {
        $("#show").slideUp(1000);
        //$("#show").hide();
        // $("#desctxt").show();
      });
      break;
  }
}

function getXmlFile() {
  var xmlDoc;
  if (window.ActiveXObject) {
    xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
    xmlDoc.async = false;
    xmlDoc.load("/serviceconfig.xml");
  } else if (
    document.implementation &&
    document.implementation.createDocument
  ) {
    try {
      xmlDoc = document.implementation.createDocument("", "", null);
      xmlDoc.async = false;
      //xmlDoc.load("../serviceconfig.xml");
      xmlDoc.load("./serviceconfig.xml");
    } catch (e) {
      var xmlhttp = new window.XMLHttpRequest();
      //xmlhttp.open("GET", "../serviceconfig.xml", false);
      xmlhttp.open("GET", "./serviceconfig.xml", false);
      xmlhttp.send(null);
      xmlDoc = xmlhttp.responseXML.documentElement;
    }
  } else {
    return null;
  }
  //  var xmls = new XMLSerializer();
  //  var xx = xmls.serializeToString(xmlDoc);
  return xmlDoc;
}

function openYSJ() {
  var xx = getXmlEle(mylx, "fwdzxq");
  window.open(xx);
}

function getxml(lx, nodename) {
  var path = "services/" + lx + "/" + nodename;
  //var path = "services/" + "zjgxsl" + "/" + "fwmc";
  var xx = orderDoc.getElementsByTagName(path)[0].nodeTypedValue;
  return xx;
}

function getXmlEle(lx, nodename) {
  var ttt = orderDoc.getElementsByTagName(lx)[0];
  var aaa = "c";
  for (var i = 0; i < ttt.childNodes.length; i++) {
    if (ttt.childNodes[i].nodeName == nodename) {
      var aaa = ttt.childNodes[i].childNodes[0].nodeValue;
    }
  }
  return aaa;
}
</script>
<style scoped>
@import url("../css/base.css");
@import url("../css/main.css");
</style>