<template>
  <div>
    <div
      style="
        position: fixed;
        z-index: 99999;
        width: 100%;
        height: 176px;
        background-color: #f2f2f2;
      "
    >
      <headV />
    </div>
    <div
      style="
        width: 100%;
        position: absolute;
        top: 176px;
        background-color: #f2f2f2;
      "
    >
      <!-- 下载资源-->
      <div class="main">
        <div class="resource container">
          <div class="resource">
            <div class="resource_tit">
              <span>操作管理</span>
            </div>
            <div class="conten" style="min-height: 779px">
              <div class="layui-fluid">
                <div class="layui-row layui-col-space15">
                  <div class="layui-col-md12">
                    <div class="layui-card">
                      <div class="layui-card-body" style=" margin-left: 70px;">
                        <form
                          class="layui-form layui-col-space5"
                          style="display: flex; justify-content: left"
                        >
                          <div class="layui-input-inline layui-show-xs-block">
                            <input
                              id="gsd"
                              type="text"
                              name="gsd"
                              placeholder="请输归属地"
                              autocomplete="off"
                              class="layui-input"
                              lay-verify="gsd"
                              style="width: 150px"
                            />
                          </div>
                          <div class="layui-input-inline layui-show-xs-block">
                            <input
                              id="username"
                              type="text"
                              name="username"
                              placeholder="请输用户名称"
                              autocomplete="off"
                              class="layui-input"
                              lay-verify="username"
                              style="width: 150px"
                            />
                          </div>
                          <div class="layui-input-inline layui-show-xs-block">
                            <input
                              id="ip"
                              type="text"
                              name="ip"
                              placeholder="请输入IP"
                              autocomplete="off"
                              class="layui-input"
                              lay-verify="ip"
                              style="width: 150px"
                            />
                          </div>
                          <div class="layui-input-inline layui-show-xs-block">
                            <input
                              id="widgetname"
                              type="text"
                              name="widgetname"
                              placeholder="请输入模块名称"
                              autocomplete="off"
                              class="layui-input"
                              lay-verify="widgetname"
                              style="width: 150px"
                            />
                          </div>
                          <div class="layui-input-inline layui-show-xs-block">
                            <label class="layui-form-label">日期范围</label>
                            <div
                              class="layui-input-inline"
                              style="width: 150px"
                            >
                              <input
                                type="text"
                                autocomplete="off"
                                class="layui-input"
                                readonly
                                id="timeRange"
                                placeholder=" - "
                              />
                            </div>
                          </div>

                          <div class="layui-input-inline layui-show-xs-block">
                            <button
                              type="button"
                              class="layui-btn"
                              lay-filter="sreach"
                              id="doSearch"
                            >
                              <i class="layui-icon">&#xe615;</i>
                            </button>
                          </div>
                        </form>
                      </div>
                      <div class="layui-card-body" style="width: 937px;margin-left: 70px;margin-top:-20px">
                        <table id="tab" class="layui-table layui-form"></table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- footer -->
      <div class="footer_bg container" style="padding-top: 20px; height: 188px">
        <div class="footer">
          <foot />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import headV from "./head.vue";
import { ref, onMounted, onBeforeMount } from "vue";
import foot from "./footer.vue";
import service from "./service.vue";
import "../js/bootstrap-table";

const maptype = ref(null);

onMounted(() => {
  $("#leftPanel li").bind("click", function () {
    $("li").removeClass("srvSelected");
    $(this).addClass("srvSelected");
    maptype.value.inimap($(this).attr("id")); //调用子组件的方法
  });
  //==============
  getData(wzmc);
  init();
  $("#doSearch").click(function () {
    doSearch();
  });
});

var wzmc = "天地图·江阴";

//通过url获取用户登录数据
function getData(wzmc) {
  $.ajax({
    type: "get",
    url: "http://101.132.131.9:5010/api/Widgetdj/djGetwzmc",
    //url: "http://101.132.131.9:5050/api/queryczglall",
    //url: "http://127.0.0.1:5050/api/queryczglall",
    data: {
      wzmc: wzmc,
    },
    async: false,
    dataType: "json",
    success: function (result) {
      var loginData = result;
      loadData(loginData);
      //console.log("asd", loginData);
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {
      alert("请求失败：" + textStatus);
    },
  });
}
var s_startDate;
var s_endDate;
function init() {
  layui.use(["laydate", "form"], function () {
    var laydate = layui.laydate,
      form = layui.form;
    laydate.render({
      elem: "#timeRange",
      range: true,
      done: function (value, date) {
        if (value == "") {
          s_startDate = "";
          s_endDate = "";
        } else {
          var startDate = value.split(" - ")[0];
          var endDate = value.split(" - ")[1];

          s_startDate = startDate.split("-").join("-");
          s_endDate = endDate.split("-").join("-");
        }
      },
    });
  });
}
//查询表数据
function doSearch() { 
  var searchObj = {};
  var username = $("#username").val();
  var ip = $("#ip").val();
  var gsd = $("#gsd").val();
  var widgetname = $("#widgetname").val();
  var wzmc = "天地图·江阴";
  searchObj.wzmc = wzmc;
  if (username == null || username.length == 0) {
    username = "";
  } else {
    searchObj.username = username;
  }

  if (ip == null || ip.length == 0) {
    ip = "";
  } else {
    searchObj.ip = ip;
  }

  if (gsd == null || gsd.length == 0) {
    gsd = "";
  } else {
    searchObj.gsd = gsd;
  }

  if (widgetname == null || widgetname.length == 0) {
    widgetname = "";
  } else {
    searchObj.widgetName = widgetname;
  }

  if (s_startDate != "" && s_endDate != "") {
    searchObj.startDate = s_startDate;
    searchObj.endDate = s_endDate;
  }

  $.ajax({
    type: "get",
    url:
      "http://101.132.131.9:5010/api/Widgetdj/SearchWidgetLog?" +
      $.param(searchObj),
    cache: false,
    dataType: "json",
    beforeSend: function (xhr) {
      xhr.setRequestHeader("Authorization", "Bearer ");
    },

    success: function (data) {
      var loginData = data;
      loadData(loginData);
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {
      alert("服务错误：" + textStatus);
    },
  });
}
//加载表数据
function loadData(loginData) {
  if (loginData == null) return;

  let new_tbData = $.extend(true, [], loginData); // 复制原始数据
  layui.use("table", function () {
    var table = layui.table;
    var options = {
      elem: "#tab",
      data: new_tbData,
      //url:url,
      height: 500,
      cols: [
        [
          //表头
          {
            field: "id",
            width: 60,
            title: "序号",
            type: "numbers",
            // templet: '#zizeng'
          },
          {
            field: "username",
            title: "用户名称",
            width: 150,
          },
          {
            field: "ip",
            title: "登录IP",
            width: 150,
          },
          {
            field: "gsd",
            title: "归属地",
            width: 150,
          },
          {
            field: "widgetname",
            title: "模块名称",
            width: 150,
          },
          {
            field: "djrq",
            title: "登录时间",
            width: 241,
            templet: function (d) {
              var str = d.djrq.substring(0, 10);
              return str;
            },
          },
        ],
      ],
      skin: "row", //表格风格
      even: true,
      page: true,
      limit: 10, //每页默认显示的数量
    };
    table.on("row(tab)", function (obj) {
      $(".layui-table-body tr:nth-child(odd)").attr({
        style: "background:#Fffffff",
      });
      $(".layui-table-body tr:nth-child(even)").attr({
        style: "background:#F2F2F2",
      }); //其他tr恢复原样
      $(obj.tr[0]).attr({
        style: "background:#99ff99",
      }); //改变当前tr颜色
    });
    //展示已知数据
    table.render(options);
  });
}
</script>
<style scoped>
.layui-btn{
  background-color :#005492!important
}
/* @import url("../css/nav.css");
@import url("../css/index.css");
@import url("../css/resource.css"); */
@import url("../css/fresh-bootstrap-table.css");
</style>