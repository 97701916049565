/*import Util from '../../../util/util'

import Province from  '../province/province'

export default {*/

$(function() {ainit()});


   function ainit() {
        // 加载省市互通信息
//      Province.init();
        // 事件注册
        eventRegister ();
    }
    /**
     * 事件监听
     */
   function eventRegister (){
        // 移动端菜单按钮点击事件
        mobileMenuClick ();
        // 拥有二级菜单的导航事件
        navHasItemsEvent ();
        // 窗口尺寸变化事件
        // windowResize ();

    }
    /**
     * 移动端菜单按钮点击事件
     */
   function mobileMenuClick () {
        // 导航菜单显隐切换
        let navbarToggle = document.querySelector('.navbar-toggle');

        if (navbarToggle){
            navbarToggle.addEventListener('click', () => {
                let navbar = document.getElementById('navbar');
                toggle (navbar);
            });

        }

    }
    /**
     * 移动端拥有二级菜单的导航点击事件
     */
   function navHasItemsEvent () {
        // 上一次激活的菜单
        let preActiveElement, hoverMenuLeave, hoverMenuTimer;
        // 菜单展开折叠切换
        let menuItems = document.querySelectorAll('.has-items');

        // for (let m of menuItems) {
        //     (function (m, ctx) {
        //         m.addEventListener('click',  () => {           
        //             if (ctx.isMobile ()){
        //                 if (leaf){
        //                     let leaf = m.querySelector('.leaf');
        //                     toggle(leaf);
        //                 }

        //             }                      
        //         });
        //     })(m, this);
        // }


        for (let m of menuItems) {
            if (m){
                m.addEventListener('click',  () => {           
                    
                    let leaf = m.querySelector('.leaf');
                    if(leaf){
                        toggle(leaf);
                    }
                    
                                    
                });
            }

           
        }

        let hoverMenu = document.querySelector('.hover-menu');
      
        enterLeave(menuItems, hoverMenu, function (evt, m) {
            let id = m.getAttribute('data-target');
            let activeElement = document.getElementById(id);
            addClass(activeElement, 'active');
            if (preActiveElement){
                removeClass(preActiveElement, 'active');
            }
            preActiveElement = activeElement;
        }, function () {

        }, this,  this.isMobile);
    }
    /**
     * pc端菜单悬浮事件
     */
  function pcMenuHover () {

    }
    /**
     * 窗口变化的情况下,
     * 如果是PC端需要隐藏移动端的二级菜单，
     * 如果是移动端需要显示下拉箭头
     */
  function windowResize () {
        window.addEventListener('resize', ()=>{
            if (!isMobile()){
                let mobileDoms = document.querySelectorAll('.mobile');
                for (let mobileDom of mobileDoms){
                    mobileDom.style.display = 'none';
                }
            } else {
                let downIcons = document.querySelectorAll('.mobile.glyphicon-menu-down');
                for (let downIcon of downIcons){
                    downIcon.style.display = 'block';
                }
            }
        });
    }
    
    /**
     * 是否是手机端
     * @return {boolean}
     */
   function isMobile () {
        let navbarToggle = document.querySelector('.navbar-toggle');
        // let display = getStyle(navbarToggle, 'display');
        let display = navbarToggle.display;
        return display != 'none';
    }
/*}*/
function camelCase(name) {
    return name.replace(SPECIAL_CHARS_REGEXP, function(_, separator, letter, offset) {
        return offset ? letter.toUpperCase() : letter;
    }).replace(MOZ_HACK_REGEXP, 'Moz$1');
}

/**
 * 获取样式属性值
 * @param element
 * @param styleName
 * @return {*}
 */
function getStyle (element, styleName) {
    if (!element || !styleName) return null;
    styleName = camelCase(styleName);
    if (styleName === 'float') {
        styleName = 'cssFloat';
    }
    try {
        const computed = document.defaultView.getComputedStyle(element, '');
        return element.style[styleName] || computed ? computed[styleName] : null;
    } catch(e) {
        return element.style[styleName];
    }
}

/**
 * 计算rem
 * @param doc
 * @param win
 */
function calculateRem (doc, win) {
    var docEl = doc.documentElement,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        reCalc = function () {
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if(clientWidth>=1280){
                docEl.style.fontSize = '100px';
            }else{
                docEl.style.fontSize = 100 * (clientWidth / 1280) + 'px';
            }
        };
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, reCalc, false);
    doc.addEventListener('DOMContentLoaded', reCalc, false);
}

/**
 * 元素显隐切换
 * @param ele
 */
function toggle (ele) {
    let display = getStyle(ele, 'display');
    display = display === 'none'?'block':'none';
    console.log(ele);
    ele.style.display = display;
}

/**
 * dom元素添加类
 * @param obj
 * @param cls
 */
function addClass(obj, cls){
    var obj_class = obj.className,//获取 class 内容.
        blank = (obj_class != '') ? ' ' : '';//判断获取到的 class 是否为空, 如果不为空在前面加个'空格'.
    var added = obj_class + blank + cls;//组合原来的 class 和需要添加的 class.
    obj.className = added;//替换原来的 class.
}

/**
 * dom元素移除类
 * @param obj
 * @param cls
 */
function removeClass(obj, cls){
    var obj_class = ' '+obj.className+' ';//获取 class 内容, 并在首尾各加一个空格. ex) 'abc    bcd' -> ' abc    bcd '
    obj_class = obj_class.replace(/(\s+)/gi, ' '),//将多余的空字符替换成一个空格. ex) ' abc    bcd ' -> ' abc bcd '
        removed = obj_class.replace(' '+cls+' ', ' ');//在原来的 class 替换掉首尾加了空格的 class. ex) ' abc bcd ' -> 'bcd '
    var removed = removed.replace(/(^\s+)|(\s+$)/g, '');//去掉首尾空格. ex) 'bcd ' -> 'bcd'
    obj.className = removed;//替换原来的 class.
}

/**
 * 悬浮某一元素，显示另一元素
 * @param showElements {NodeList} 显示元素集合或者单个元素
 * @param hideElement  { Object }       隐藏元素
 * @param showEnterFn  { function } 显示元素悬浮时的回调函数
 * @param showLeaveFn  { function } 显示元素离开时的回调函数
 * @param ctx          { Object } 上下文
 * @param capture      { function } 函数返回true, 不执行回调函数
 */
function enterLeave(showElements, hideElement, showEnterFn, showLeaveFn, ctx, capture) {
    var leave = false, timer = null;
    if (Object.prototype.toString.call(showElements).indexOf('array') == -1
        && Object.prototype.toString.call(showElements).indexOf('NodeList') == -1 ){
        showElements = [showElements];
    }

    for (var s = 0; s < showElements.length; s++){
        var showElement = showElements[s];
        (function (showElement, idx) {
            showElement.addEventListener('mouseenter', function (evt) {
                if (typeof capture === 'function' && capture()){
                    return;
                }
                leave = false;
                hideElement.style.display = 'block';
                if (showEnterFn){
                    if (!ctx){
                        ctx = showElement;
                    }
                    showEnterFn.call(ctx, evt, showElement, idx);
                }
            });

            showElement.addEventListener('mouseleave', function (evt) {
                if (typeof capture === 'function' && capture()){
                    return;
                }
                mouseLeave();
                if (showLeaveFn){
                    if (!ctx){
                        ctx = showElement;
                    }
                    showLeaveFn.call(ctx, evt, showElement);
                }
            });
        })(showElement,s);
    }

    if (hideElement) {
        hideElement.addEventListener('mouseenter', function () {
            leave = false;
        });
        hideElement.addEventListener('mouseleave', mouseLeave);
    }



    



    function mouseLeave() {
        leave = true;

        if (timer){
            return;
        }

        timer = setTimeout(function () {
            if (leave){
                hideElement.style.display = 'none';
            }
            timer = null;
        }, 200);
    }
}
