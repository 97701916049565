import { createApp } from 'vue'
import App from './components/approuter'
// import App from './App.vue'
// import App from './pages/ContentUs.vue'
// import App from './pages/resource.vue'
// import App from './pages/Disclaimer.vue'
// import App from './pages/ExamplePage.vue'
// import App from './pages/home.vue'
// import App from './pages/updateLog.vue'
// import App from './pages/head.vue'
import store from './store'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'font-awesome/css/font-awesome.min.css'
import 'leaflet-contextmenu/dist/leaflet.contextmenu.min.css'
import mitt from 'mitt'
import $ from 'jquery'
import 'bootstrap'
 
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
 
 


import router from './router'
// createApp(App).use(store).mount('#app')

const bus=mitt()
const app=createApp(App)
app.config.globalProperties.$bus=bus
// app.use(store).mount('#app')
app.use(router,store).mount('#app') 
 