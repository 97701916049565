<template>
  <div>
    <div class="logo_con">
      <div class="container portal_logo">
        <h1>
          江阴市地理信息公共服务平台<br />
          <div style="position: relative; top: -24px">
            <small>Jiangyin City Platform for Common GeoSpatial Information
              Services</small>
          </div>
        </h1>
      </div>
    </div>
    <!-- 导航栏 -->
    <nav class="navbar">
      <div class="container">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
        </div>
        <div id="navbar" class="collapse navbar-collapse">
          <ul class="nav navbar-nav">
            <li>
              <a target="_blank" href="/jiangyin" v-on:click="getcontent('首页')">首页</a>
            </li>
            <li class="">
              <!--  <router-link :to="{ path: '/Emap' }" target="_blank"  v-on:click="getcontent('电子地图')">电子地图</router-link> -->
              <a target="_blank" href="./Emap/" v-on:click="getcontent('电子地图')">电子地图</a>
            </li>
            <li class="">
              <a target="_blank" href="./Emap3D/" v-on:click="getcontent('电子地图三维版')">电子地图三维版</a>
            </li>
            <li class="has-items" data-target="resourceOnline" id="85">
              <a href="http://lbs.tianditu.gov.cn/home.html" target="_blank" v-on:click="getcontent('开发资源')">开发资源</a>
            </li>
            <li>
              <!-- <router-link :to="{ path: '/updateLog' }" target="_blank"  v-on:click="getcontent('网站公告')">网站公告</router-link> -->
              <a href="#/updateLog" target="_blank" v-on:click="getcontent('网站公告')">网站公告</a>
            </li>
            <li class="has-items" data-target="typical" id="85">
              <!-- <router-link :to="{ path: '/ExamplePage' }" target="_blank"  v-on:click="getcontent('典型应用')">典型应用</router-link> -->
              <a target="_blank" href="#/ExamplePage" v-on:click="getcontent('典型应用')">典型应用</a>
            </li>
            <!-- <li class="has-items" data-target="childnode" id="85">
							<a href="">县级节点</a>

						</li> -->
          </ul>
          <div class="hover-menu">
            <ul id="resourceOnline" class="pc">
              <li>
                <a href="http://lbs.tianditu.gov.cn/server/MapService.html" target="_blank">地图API</a>
              </li>
              <li>
                <a href="http://lbs.tianditu.gov.cn/home.html" target="_blank">网页API</a>
              </li>
              <li>
                <a href="http://lbs.tianditu.gov.cn/server/guide.html" target="_blank">WEB服务API</a>
              </li>
              <li>
                <a href="http://lbs.tianditu.gov.cn/data/dataapi.html" target="_blank">数据API</a>
              </li>
              <!-- <li>
                <a href="http://service.tianditu.gov.cn/" target="_blank">服务资源</a>
              </li> -->
              <!--<li>
								<a href="http://lbs.tianditu.gov.cn/android/guide.html" target="_blank">移动SDK</a>
							</li>-->
            </ul>
            <ul id="typical" class="pc">
              <li>
                <a href="./static/jyjjzb/map/index.html" target="_blank">经济指标</a>
              </li>
             
            </ul>
            <!-- <ul id="childnode" class="pc">
              <li>
                <a
                  hidefocus
                  href="http://jiangsu.tianditu.gov.cn/danyang/"
                  target="_blank"
                  >丹阳</a
                >
              </li>
              <li>
                <a
                  hidefocus
                  href="http://jiangsu.tianditu.gov.cn/yangzhong/"
                  target="_blank"
                  >扬中</a
                >
              </li>
            </ul> -->
            <div id="provinceCity" class="district-info pc"></div>
            <div class="city-box">
              <div class="up-arrow"></div>
              <ul class="clearfix"></ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>


<script setup>
import "../js/nav";
import { RouterLink } from "vue-router";
//import returnCitySN from 'returnCitySN'
function router2page(page) {
  console.log(page);
  let $this = this;
  $this.$router.push({ path: "/" + page });
  // let text= this.$router.resolve({path:'/'+page})
  console.log("asd", text.href);
  //    window.open(text.href, '_blank')
}

var isn = returnCitySN;
var ip = isn["cip"];
var gsd = isn["cname"];
var wzmc = "天地图·江阴";
var username = "vue_user";
//document.write("<span id='ip'>"+'您的IP：' + ip + '，归属地为：' + gsd +"</span>");
//时间
var i = new Date();
var h = i.getHours();
var m = i.getMinutes();
var sj = h + ":" + m;
//日期 xxxx-xx-xx
var mydate = new Date();
var rq = "" + mydate.getFullYear() + "-";
if (mydate.getMonth() + 1 < 10) {
  var month = "0" + (mydate.getMonth() + 1) + "-";
} else {
  var month = mydate.getMonth() + 1 + "-";
}
rq += month;
if (mydate.getDate() < 10) {
  var day = "0" + mydate.getDate();
} else {
  var day = mydate.getDate();
}
rq += day;
//console.log("sss",rq)
//guid
var tid = guid();
function guid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}


//用户点击模块记录信息

function getcontent(obj) {
  var widgetname = obj;
  console.log(widgetname);
  //insertwidgetfwsj(username, ip, wzmc, widgetname, rq, tid, gsd);
}

</script>



<style scoped>
@import url("../css/nav.css");
@import url("../css/index.css");
/* @import url("../css/logupdate/css/history.css"); */
</style>